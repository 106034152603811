import { round } from 'lodash';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { dayjs } from '@amalia/ext/dayjs';
import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';

import { type PaymentAmountsByCategory, PaymentCategory } from '../types/payments';

const MIN_AMOUNT = 0.01;

const numberFormatter = new Intl.NumberFormat();

/**
 * Each amount -MIN_VALID_AMOUNT < amount < MIN_VALID_AMOUNT is considered to be 0.
 * @deprecated use formatAmount from kernel/intl/formatters or useFormatAmount from kernel/intl/components
 */
const MIN_VALID_AMOUNT = 0.005;
export const formatAmount = (amount: number, currency: CurrencySymbolsEnum) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: FormatsEnum.currency,
    currency,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  });

  // If the amount is contained between -0.004999... and 0.0049999..., it
  // will be rounded then displayed as -0 and we want to avoid that.
  const cappedAmount = Math.abs(amount) < MIN_VALID_AMOUNT ? 0 : amount;

  return formatter.format(cappedAmount);
};

/** 0.01 => 2 decimal places. */
const getDecimalPlacesFromRoundingConstant = (roundingConstant: number): number =>
  Math.max(Math.ceil(Math.log10(1 / roundingConstant)), 0);

const roundNumber = (numberToRound?: number | null, step = 0.01): number => {
  // If payment is next to 0, return 0
  if (!numberToRound || (numberToRound < MIN_AMOUNT && numberToRound > -1 * MIN_AMOUNT)) {
    return 0;
  }

  return round(numberToRound, getDecimalPlacesFromRoundingConstant(step));
};

const formatTotalForDate = (total: number | string): string => {
  const isUnixTimestamp = dayjs(total, 'X', true).isValid();
  if (isUnixTimestamp) {
    return dayjs(total, 'X').format('YYYY-MM-DD');
  }

  return `${total}`;
};

const formatTotalForPercent = (total: number): string =>
  new Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumSignificantDigits: 1,
    roundingPriority: 'lessPrecision',
  }).format(roundNumber(total * 100, 0.01) / 100);

const formatTotalForNumber = (total: number): string => numberFormatter.format(roundNumber(total));

/** @deprecated use formatValue from kernel/intl/formatters or useFormatValue from kernel/intl/components */
export const formatTotal = (
  total: ComputeEnginePrimitiveTypes | undefined,
  format: FormatsEnum,
  currencySymbol: CurrencySymbolsEnum = CurrencySymbolsEnum.EUR,
  currencyRate: number = 1,
): string => {
  if (total === 'Infinity') {
    return 'Infinity';
  }
  if (total === null) {
    return 'null';
  }
  switch (format) {
    case FormatsEnum.table:
      return '';
    case FormatsEnum.text:
      return `${total}`;
    case FormatsEnum.date:
    case FormatsEnum['date-time']:
      return formatTotalForDate(total as number | string);
    case FormatsEnum.percent:
      return formatTotalForPercent(total as number);
    case FormatsEnum.number:
      return formatTotalForNumber(total as number);
    case FormatsEnum.boolean:
      return `${total}`;
    // Default usage for this function is to format as currency.
    case FormatsEnum.currency:
      return isCurrencyValue(total)
        ? formatAmount(roundNumber(total.value) * currencyRate, total.symbol)
        : formatAmount(roundNumber(total as number) * currencyRate, currencySymbol);
    default:
      return formatAmount(roundNumber(total as number) * currencyRate, currencySymbol);
  }
};

/** @deprecated use formatValue with a text format if you don't know the format. */
export const formatValueTotal = (value: ComputeEnginePrimitiveTypes | undefined): string | undefined =>
  value === null || value === undefined ? undefined : value.toString();

/** @deprecated yeah don't use that. */
export const formatValueOrPrintRemovedLabel = (value: number | string | null | undefined): string | undefined =>
  value === null || value === undefined || value === '' ? 'deleted' : formatValueTotal(value);

/** @deprecated */
export const formatDatasetCell = (
  cellValue: ComputeEnginePrimitiveTypes,
  forceFormat?: FormatsEnum,
): number | string | undefined => {
  // Only parse as date if it's a timestamp, otherwise show the exact value
  if (typeof cellValue === 'number' && forceFormat === FormatsEnum.date && cellValue > 10_000) {
    return cellValue ? dayjs.utc(cellValue, 'X').format('YYYY-MM-DD') : '';
  }

  if (forceFormat) {
    const value =
      cellValue && typeof cellValue === 'object' && 'value' in cellValue ? cellValue.value : (cellValue as number);
    return formatTotal(value, forceFormat);
  }

  // Case for currencies.
  if (isCurrencyValue(cellValue)) {
    const { value, symbol } = cellValue;
    return formatTotal(value, FormatsEnum.currency, symbol);
  }

  // Display as-is.
  return formatValueTotal(cellValue);
};

/**
 * Note: not sure why this is in core/types and formatters
 */
export const showPayment = (
  allAmounts: Partial<PaymentAmountsByCategory> | undefined,
  category: PaymentCategory,
): boolean => {
  if (!allAmounts) {
    return false;
  }

  const categoryAmount = allAmounts[category];
  const roundedCategoryAmount = roundNumber(categoryAmount ?? 0);

  // Show future payments if different than 0
  if (category === PaymentCategory.hold) {
    return roundedCategoryAmount !== 0;
  }

  // Show if different than achievement
  if (category === PaymentCategory.paid) {
    const achievedAmount = roundNumber(allAmounts[PaymentCategory.achievement]);
    return roundedCategoryAmount !== achievedAmount;
  }

  return categoryAmount !== undefined;
};
