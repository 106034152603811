import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { type ElementType, type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { CollapseIconChevron } from '../../../general/collapse-icon-chevron/CollapseIconChevron';
import { type TablerIconElement } from '../../../general/icons/types';
import { type SelectDropdownChildRenderProps } from '../../../overlays/select-dropdown/SelectDropdown.types';
import { Input, type InputProps } from '../../input/Input';
import { InputSize } from '../../input/Input.types';
import { type MultiValueLabelProps, type SelectOption, SelectSize } from '../Select.types';

import { MultiValueContainer } from './multi-value-container/MultiValueContainer';
import * as styles from './SelectControl.styles';
import { SingleValue, type SingleValueProps } from './single-value/SingleValue';

const SELECT_SIZE_INPUT_SIZE_MAPPING: Record<SelectSize, InputSize> = {
  [SelectSize.SMALL]: InputSize.SMALL,
  [SelectSize.MEDIUM]: InputSize.MEDIUM,
};

export type SelectControlProps<
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
> = SelectDropdownChildRenderProps<TOption, TIsMultiple> & {
  /** Field id. */
  readonly id?: string;
  /** Input name. */
  readonly name?: string;
  /** Input size. */
  readonly size?: SelectSize;
  /** Left icon. */
  readonly icon?: TablerIconElement;
  /** Is the select disabled. */
  readonly disabled?: boolean;
  /** Is multi-select. */
  readonly isMultiple?: TIsMultiple;
  /** Can clear the value in a single select. */
  readonly isClearable?: TIsClearable;
  /* The tooltip to show on the clear action. */
  readonly clearActionTooltip?: string;
  /** Override default placeholder. */
  readonly placeholder?: string;
  /** Validation error. */
  readonly error?: InputProps['error'];
  /** Component to render the label of the selected option inside the control on a single select component. */
  readonly SingleValueLabelComponent?: SingleValueProps<TOption>['LabelComponent'];
  /** Component to render the label of an selected option inside the control on a multi select component. */
  readonly MultiValueLabelComponent?: ElementType<MultiValueLabelProps<TOption>>;
};

const SelectControlInnerRef = forwardRef(function SelectControl<
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
>(
  {
    id,
    name,
    value,
    onClear,
    isDropdownOpen,
    size = SelectSize.MEDIUM,
    icon,
    disabled,
    isMultiple,
    isClearable,
    clearActionTooltip,
    placeholder,
    error,
    SingleValueLabelComponent,
    MultiValueLabelComponent,
  }: SelectControlProps<TOption, TIsMultiple, TIsClearable>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { formatMessage } = useIntl();

  const valuePlaceholder =
    placeholder ??
    (isMultiple ? formatMessage({ defaultMessage: 'Select items' }) : formatMessage({ defaultMessage: 'Select item' }));

  const hasValue = !isEmpty(value);

  return (
    <div
      css={styles.controlContainer}
      data-testid="select-control-container"
    >
      <Input
        ref={ref}
        readOnly
        aria-expanded={isDropdownOpen}
        css={styles.control}
        data-testid="select-control"
        disabled={disabled}
        error={error}
        id={id}
        leftIcon={icon}
        name={name}
        placeholder={valuePlaceholder}
        rightIcon={<CollapseIconChevron isOpen={isDropdownOpen} />}
        role="combobox"
        showError={false}
        size={SELECT_SIZE_INPUT_SIZE_MAPPING[size]}
        value={hasValue ? ' ' : ''} // Hide placeholder if there is a value.
        action={
          hasValue && (isMultiple || isClearable) ? (
            <Input.ClearAction
              label={clearActionTooltip}
              onClick={onClear}
            />
          ) : undefined
        }
      />

      {!!hasValue && (
        <div
          css={styles.valueContainer}
          className={clsx(size, {
            [styles.HAS_ICON_CLASSNAME]: !!icon,
            [styles.IS_MULTIPLE_CLASSNAME]: isMultiple,
            [styles.IS_CLEARABLE_CLASSNAME]: isClearable,
          })}
        >
          {isMultiple ? (
            <MultiValueContainer
              disabled={disabled}
              LabelComponent={MultiValueLabelComponent}
              options={value as TOption[]}
              size={size}
            />
          ) : (
            <SingleValue
              disabled={disabled}
              LabelComponent={SingleValueLabelComponent}
              option={value as TOption}
              size={size}
            />
          )}
        </div>
      )}
    </div>
  );
});

export const SelectControl = memo(SelectControlInnerRef) as <
  TOption extends SelectOption = SelectOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = true,
>(
  props: SelectControlProps<TOption, TIsMultiple, TIsClearable> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReactElement | null;
