import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { Modal, Stack, Typography } from '@amalia/design-system/components';

type ConfirmSaveGlobalTokenModalProps = {
  readonly confirmCallback: () => Promise<void>;
  readonly usedInPlans: { name: string; id: string }[];
  readonly tokenType: TokenType.FIELD | TokenType.FILTER | TokenType.VARIABLE;
  readonly tokenName: string;
  readonly isModalOpen: boolean;
  readonly onCloseModal: () => void;
};

const TokenTypeToMessage = defineMessages<ConfirmSaveGlobalTokenModalProps['tokenType']>({
  [TokenType.FIELD]: {
    defaultMessage: 'This column is used in {plansCount, plural, one {another plan} other {other plans}}',
  },
  [TokenType.FILTER]: {
    defaultMessage: 'This table is used in {plansCount, plural, one {another plan} other {other plans}}',
  },
  [TokenType.VARIABLE]: {
    defaultMessage: 'This variable is used in {plansCount, plural, one {another plan} other {other plans}}',
  },
});

export const ConfirmSaveGlobalTokenModal = memo(function ConfirmSaveGlobalTokenModal({
  isModalOpen,
  tokenName,
  onCloseModal,
  tokenType,
  confirmCallback,
  usedInPlans,
}: ConfirmSaveGlobalTokenModalProps) {
  if (isEmpty(usedInPlans)) return null;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              {...TokenTypeToMessage[tokenType]}
              values={{ plansCount: usedInPlans.length }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Stack gap={16}>
            <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
              <FormattedMessage
                defaultMessage="Saving changes for <bold>{tokenName}</bold> will also impact the following {plansCount, plural, one {plan} other {plans}}: {plansUsedInNames}"
                values={{
                  tokenName,
                  plansCount: usedInPlans.length,
                  bold: (text) => <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{text}</Typography>,
                  plansUsedInNames:
                    usedInPlans.length > 1 ? (
                      <Stack
                        gap={6}
                        css={css`
                          margin-top: 16px;
                        `}
                      >
                        {usedInPlans.map((plan) => (
                          <Typography
                            key={plan.id}
                            variant={Typography.Variant.BODY_BASE_BOLD}
                          >
                            {plan.name}
                          </Typography>
                        ))}
                      </Stack>
                    ) : (
                      <Typography variant={Typography.Variant.BODY_BASE_BOLD}>{usedInPlans[0].name}</Typography>
                    ),
                }}
              />
            </Typography>
            <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
              <FormattedMessage defaultMessage="Are you sure you want to save changes?" />
            </Typography>
          </Stack>
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={confirmCallback}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
