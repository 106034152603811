import { type OmitHead } from '@amalia/ext/typescript';

import { printNullOrInfinity } from '../../helpers/print-null-or-infinity/print-null-or-infinity';
import { roundNumber } from '../../helpers/round-number/round-number';
import { type FormatOptions } from '../types';

export const formatPercent = (
  locale: string,
  value: number | null | undefined,
  {
    intlOptions,
    formatOptions,
  }: {
    intlOptions?: Omit<Intl.NumberFormatOptions, 'style'>;
    formatOptions?: FormatOptions;
  } = {},
) =>
  printNullOrInfinity(value, formatOptions?.printNullOrInfinity, () =>
    new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      minimumSignificantDigits: 1,
      roundingPriority: 'lessPrecision',
      ...intlOptions,
      style: 'percent',
    }).format(roundNumber((value ?? 0) * 100, 0.01) / 100),
  );

export type FormatPercent = (...args: OmitHead<Parameters<typeof formatPercent>>) => ReturnType<typeof formatPercent>;
