import { useIntl } from 'react-intl';

import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

export const getCurrencySymbolCharacters = (locale: string, currencySymbol: CurrencySymbolsEnum) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency: currencySymbol })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value ?? '';

export const useCurrencySymbolCharacters = (currencySymbol: CurrencySymbolsEnum) =>
  getCurrencySymbolCharacters(useIntl().locale, currencySymbol);
