import { useQuery } from '@tanstack/react-query';

import {
  type CustomReport,
  type CustomReportConfiguration,
  type CustomReportDataSourceConfiguration,
  type CustomReportRecordsRequest,
} from '@amalia/reporting/custom-reports/shared';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import { customReportsQueryKeys } from './custom-reports.keys';

export const useCustomReportContent = (
  customReportId: CustomReport['id'] | undefined,
  configuration: CustomReportConfiguration | undefined,
  dataSourceConfiguration: CustomReportDataSourceConfiguration | undefined,
  pagination: CustomReportRecordsRequest['pagination'],
) =>
  useQuery({
    queryKey: customReportsQueryKeys.ofCustomReport.content(
      customReportId!,
      configuration,
      dataSourceConfiguration,
      pagination,
    ),
    queryFn: () =>
      CustomReportsApiClient.getCustomReportContent(
        customReportId!,
        configuration,
        dataSourceConfiguration,
        pagination,
      ),
    enabled: !!customReportId,
  });
