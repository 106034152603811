import { type CredentialBody } from 'google-auth-library/build/src/auth/credentials';

import { type BamboohrConnectorAuth } from './bamboohrConnectorAuth';
import { type DatabaseConnectorAuth } from './databaseConnectorAuth';
import { type OAuthDataConnectorAuth } from './oAuthDataConnectorAuth';
import { type SalesforceClientCredentialsAuth } from './salesforceClientCredentialsAuth';
import { type SnowflakeConnectorAuth } from './snowflakeConnectorAuth';
import { type SQLServerConnectorAuth } from './sql-server-connector-auth';

export * from './databaseConnectorAuth';
export * from './oAuthDataConnectorAuth';
export * from './salesforceClientCredentialsAuth';
export * from './snowflakeConnectorAuth';
export * from './bamboohrConnectorAuth';
export * from './sql-server-connector-auth';

/**
 * All supported auth methods.
 */
export type ConnectorAuth =
  | BamboohrConnectorAuth
  | CredentialBody
  | DatabaseConnectorAuth
  | OAuthDataConnectorAuth
  | SalesforceClientCredentialsAuth
  | SnowflakeConnectorAuth
  | SQLServerConnectorAuth;

export enum AuthType {
  Database_Connection_Auth = 'Database_Connection_Auth',
  Snowflake_Connection_Auth = 'Snowflake_Connection_Auth',
  OAuth2_authorizationCode = 'OAuth2_authorizationCode',
  OAuth2_clientCredentials = 'OAuth2_clientCredentials',
  ServiceAccount = 'ServiceAccount',
  BamboohrConnectorAuth = 'BamboohrConnectorAuth',
  SQLServerConnectorAuth = 'SQLServerConnectorAuth',
}
