import { Fragment, memo } from 'react';

import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type FormatOptions } from '@amalia/kernel/intl/formatters';

import { useFormatAmount } from '../../hooks/use-format-amount';

export type FormattedAmountProps = {
  readonly value: number | null;
  readonly currencySymbol: CurrencySymbolsEnum;
  readonly currencyRate?: number;
  readonly intlOptions?: Intl.NumberFormatOptions;
  readonly formatOptions?: FormatOptions;
};

export const FormattedAmount = memo(function FormattedAmount({
  value,
  currencySymbol,
  currencyRate,
  intlOptions,
  formatOptions,
}: FormattedAmountProps) {
  const formatAmount = useFormatAmount();
  return <Fragment>{formatAmount(value, currencySymbol, { currencyRate, intlOptions, formatOptions })}</Fragment>;
});
