/**
 * All the routes of the app.
 *
 * TODO: Routes are needed in front and back, however should we split by domain?
 * In the future we will split it in feature libs.
 */
export const routes = {
  ROOT: '/',

  AUTH_IFRAME: '/auth/iframe',
  AUTH_SSO_INITIATED: '/auth/sso/initiated',
  AUTH_CONNECTOR_CALLBACK: '/auth/:connector/callback',
  AUTH_INTEGRATION_CALLBACK: '/auth-integration/:integration/callback',

  HOME: '/home',

  DATA_EXPORT: '/data-exports/:exportId',

  DESIGNER: '/designer',
  DESIGNER_OBJECT: '/designer/:planId/:objectType/:objectId',
  DESIGNER_PLAN: '/designer/:planId',

  PLANS: '/plans',
  PLAN_HUB: '/plans/:planId/:tab?',
  PLAN_HUB_ASSIGNMENTS: '/plans/:planId/assignments',
  PLAN_HUB_CUSTOMIZATION: '/plans/:planId/customization',
  PLAN_HUB_RULES: '/plans/:planId/rules',
  PLAN_HUB_RULE: '/plans/:planId/rules/:ruleId',
  PLAN_HUB_RULE_FORECAST: '/plans/:planId/rules/:ruleId/forecast',

  TEAMS: '/teams',
  TEAM_DETAILS: '/teams/:teamId',

  QUOTAS: '/quotas',
  QUOTAS_VALUE: '/quotas/values/:variableId',

  AUDIT: '/audit',

  PLAN_AGREEMENTS_LIST: '/plan-agreements',
  PLAN_AGREEMENT_EDIT: '/plan-agreements/:planAgreementId',
  PLAN_AGREEMENT_ASSIGNMENT: '/plan-agreements/:planAgreementId/assignments/:assignmentId',
  PLAN_AGREEMENT_ASSIGNMENT_OLD: '/planAgreements/:planAgreementId/assignments/:assignmentId/viewDocument',
  PLAN_AGREEMENT_PREVIEW: '/plan-agreements/:planAgreementId/preview',

  CHANGELOG: '/changelog',

  OVERWRITES: '/overwrites',

  PAYMENTS: '/payments',

  CUSTOM_REPORT_LIST: '/custom-reports',
  CUSTOM_REPORT_ITEM: '/custom-reports/:reportId',

  DATA_CONNECTORS: '/data/connectors',
  DATA_CONNECTOR: '/data/connectors/:connectorType',
  CAPTURED_RECORD_MODEL_CREATE: '/data/connectors/:connectorId/record-models/create',
  CAPTURED_RECORD_MODEL_DETAILS: '/data/record-models/:modelMachineName?',

  COMPANY: '/company',
  COMPANY_SETTINGS: '/company/settings',
  COMPANY_API: '/company/settings/api',
  COMPANY_RATES: '/company/settings/rates',
  COMPANY_WORKFLOWS: '/company/settings/workflows',
  COMPANY_PAYMENTS_LOCK: '/company/settings/payments-lock',
  COMPANY_CUSTOMIZATION: '/company/settings/customization',

  THREADS: '/threads',

  SUPERADMIN: '/superadmin',
  SUPERADMIN_HOME: '/superadmin/home',
  SUPERADMIN_SETTINGS: '/superadmin/settings',
  SUPERADMIN_COMPANIES: '/superadmin/companies',
  SUPERADMIN_COMPANY: '/superadmin/companies/:companyId',
  SUPERADMIN_CALCULATIONS: '/superadmin/calculations',
  SUPERADMIN_REFRESHMENTS: '/superadmin/refreshments',
  SUPERADMIN_CONNECTORS: '/superadmin/connectors',
  SUPERADMIN_STATEMENTS: '/superadmin/statements',
  SUPERADMIN_USERS: '/superadmin/users',
  SUPERADMIN_PLANS: '/superadmin/plans',

  DASHBOARD: '/dashboard',

  STATEMENTS: '/statements',
  STATEMENTS_BY_DATE: '/statements/by-date/:startDate',
  STATEMENTS_BY_USER_PERIOD: '/statements/by-date/:startDate/plan/:planId/user/:userId',

  STATEMENT: '/statements/:id',
  STATEMENT_COMMENT_CREATE: '/statements/:id/comments',
  STATEMENT_COMMENT: '/statements/:id/comments/:stid',
  STATEMENT_RULE: '/statements/:id/rule/:ruleid/:action?/:externalid?',

  FORECASTS: '/forecasts',
  FORECASTS_BY_DATE: '/forecasts/by-date/:startDate',
  FORECAST: '/forecasts/:id',

  DASHBOARDS_V2: '/dashboards-v2',
  DASHBOARD_V2: '/dashboards-v2/:dashboardId',
  DASHBOARD_V2_SHARE: '/dashboards-v2/:dashboardId/?share=true',
} as const;
