import { VariableType } from '@amalia/amalia-lang/tokens/types';
import { type ComputedStatement, type DatasetRow } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { CustomObjectDefinitionType } from '@amalia/data-capture/record-models/types';
import {
  ComputedItemTypes,
  type ComputedVariable,
  DatasetType,
  type FilterDataset,
} from '@amalia/payout-calculation/types';
import { UserRole } from '@amalia/tenants/users/types';

export const ruleMock = {
  id: '1c34a805-c3f3-4963-b0ca-02276b234930',
  name: 'New AE Commission',
  type: 'rule',
  total: 8686.619_534_246_574,
  formula: 'statement.newAeFinalBonusQuarter',
  machineName: 'newAeCommission',
};

export const datasetRows: DatasetRow[] = [
  {
    id: 'c88fdd8e-de6c-4fa3-a813-c04a04ffda8d',
    content: {
      id: '0061t00000T4lKiAAJ',
      name: 'YOLO',
      type: 'Renewal',
      isWon: true,
      amount: { value: 20_900, symbol: 'EUR' },
      licence: { value: 15_600, symbol: 'EUR' },
      officeC: 'DACH',
      ownerId: '0051t0000030EHYAA2',
      isClosed: true,
      paymentC: 'One Time',
      accountId: '0011t00001D6exPAAR',
      closeDate: '2020-07-27',
      stageName: '6 - Closed Won',
      expansionC: false,
      jppAmountC: { value: 11_880.38, symbol: 'EUR' },
      leadSource: 'Outbound',
      newService: { value: 0, symbol: 'EUR' },
      createdDate: '2020-05-04T10:48:21.000+0000',
      abrConverted: { value: 11_880.38, symbol: 'EUR' },
      licenceMrr2C: { value: 1300, symbol: 'EUR' },
      supportMrr2C: { value: 400, symbol: 'EUR' },
      newRatioBonus: { value: 4680, symbol: 'EUR' },
      newServiceMrr: { value: 0, symbol: 'EUR' },
      currencyIsoCode: 'EUR',
      newAbrBonusBase: { value: 16_560.379_999_999_997, symbol: 'EUR' },
      lastModifiedDate: '2020-10-08T15:11:39.000+0000',
      newMultiyearBonus: { value: 0, symbol: 'EUR' },
      previousOppsNameC: 'NB - Kneipp - 2019-07 - 09094',
      contractTermMonthsC: 12,
      sofactoappPeriodiciteC: 'Annuelle',
      previousContractEndDateC: '2020-06-30',
      renewedSubscriptionsAmountC: { value: 8769.62, symbol: 'EUR' },
    },
    externalId: '0061t00000T4lKiAAJ',
  },
  {
    id: '7cbbc755-490a-41bf-84dc-a82cc6833a0c',
    content: {
      id: '0061t00000W3enfAAB',
      name: 'SWAG',
      type: 'New Business',
      isWon: true,
      amount: { value: 25_804, symbol: 'EUR' },
      licence: { value: 24_804, symbol: 'EUR' },
      officeC: 'DACH',
      ownerId: '0051t0000030EHYAA2',
      isClosed: true,
      paymentC: 'One Time',
      accountId: '0011t00001Ch0EIAAZ',
      closeDate: '2020-09-28',
      stageName: '6 - Closed Won',
      expansionC: false,
      jppAmountC: { value: 25_804, symbol: 'EUR' },
      leadSource: 'Outbound',
      newService: { value: 0, symbol: 'EUR' },
      createdDate: '2020-08-07T13:06:45.000+0000',
      abrConverted: { value: 25_804, symbol: 'EUR' },
      licenceMrr2C: { value: 2067, symbol: 'EUR' },
      supportMrr2C: { value: 0, symbol: 'EUR' },
      newRatioBonus: { value: 7441.2, symbol: 'EUR' },
      newServiceMrr: { value: 0, symbol: 'EUR' },
      currencyIsoCode: 'EUR',
      newAbrBonusBase: { value: 33_245.2, symbol: 'EUR' },
      lastModifiedDate: '2020-10-08T15:12:12.000+0000',
      newMultiyearBonus: { value: 0, symbol: 'EUR' },
      previousOppsNameC: null,
      contractTermMonthsC: 12,
      sofactoappPeriodiciteC: 'Annuelle',
      previousContractEndDateC: null,
      renewedSubscriptionsAmountC: { value: 0, symbol: 'EUR' },
    },
    externalId: '0061t00000W3enfAAB',
  },
];

export const availableFilterMock: FilterDataset = {
  id: '',
  type: DatasetType.filter,
  filterMachineName: 'closedInQuarter',
  customObjectDefinition: { machineName: 'opportunity' },
  parentIds: [],
  computedItems: [
    {
      id: '29bee9762b5d433ae35f65581268cc4619e76337',
      type: ComputedItemTypes.VARIABLE,
      value: 31_200,
      parentIds: [
        '5849538d026b8b033577bdf2a41f7f791cb539e7',
        'ffafdac45425aa8f4d6bbf106af294c827a4fa27',
        '7c0d1bf8-1772-4731-a658-e1c5218b56f9',
      ],
      variableType: VariableType.object,
      variableMachineName: 'licenceRn',
    } as ComputedVariable,
    {
      id: 'e370612c6b8e1237c612f2d0373a300e24c729e0',
      type: ComputedItemTypes.VARIABLE,
      value: 9360,
      parentIds: [
        '5849538d026b8b033577bdf2a41f7f791cb539e7',
        'ffafdac45425aa8f4d6bbf106af294c827a4fa27',
        '7c0d1bf8-1772-4731-a658-e1c5218b56f9',
      ],
      variableType: VariableType.object,
      variableMachineName: 'serviceNb',
    } as ComputedVariable,
  ],
};

export const availableFilterMockWithRelation: FilterDataset = {
  id: '',
  type: DatasetType.filter,
  filterMachineName: 'closedInQuarter',
  customObjectDefinition: { machineName: 'opportunity' },
  parentIds: [],
  relationMachineNames: ['opportunityLineItems'],
  computedItems: [
    {
      id: '29bee9762b5d433ae35f65581268cc4619e76337',
      type: ComputedItemTypes.VARIABLE,
      value: 31_200,
      parentIds: [
        '5849538d026b8b033577bdf2a41f7f791cb539e7',
        'ffafdac45425aa8f4d6bbf106af294c827a4fa27',
        '7c0d1bf8-1772-4731-a658-e1c5218b56f9',
      ],
      variableType: VariableType.object,
      variableMachineName: 'licenceRn',
    } as ComputedVariable,
    {
      id: 'e370612c6b8e1237c612f2d0373a300e24c729e0',
      type: ComputedItemTypes.VARIABLE,
      value: 9360,
      parentIds: [
        '5849538d026b8b033577bdf2a41f7f791cb539e7',
        'ffafdac45425aa8f4d6bbf106af294c827a4fa27',
        '7c0d1bf8-1772-4731-a658-e1c5218b56f9',
      ],
      variableType: VariableType.object,
      variableMachineName: 'serviceNb',
    } as ComputedVariable,
  ],
};

export const mockStatement = {
  definitions: {
    plan: {
      id: 'a52ac536-eb8a-43e0-8473-0512b5beeb18',
      name: 'ACCOUNT EXECUTIVE 2020-2021',
      rules: [
        {
          id: '1c34a805-c3f3-4963-b0ca-02276b234930',
          name: 'New AE Commission',
          index: 0,
          kpisToDisplay: [
            {
              id: '64106d44-83ec-4678-80db-72c40a421c24',
              displayStatus: 'ON_DISPLAY',
            },
            {
              id: '61a6a183-8657-4185-b364-701b8c49948c',
              displayStatus: 'ON_DISPLAY',
            },
            { id: 'aa04e6ae-8206-477a-af49-309b77cf0c1c', displayStatus: 'ON_DISPLAY' },
          ],
          fieldsToDisplay: [
            { name: 'id', displayStatus: 'AVAILABLE' },
            {
              name: 'name',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'closeDate', displayStatus: 'ON_DISPLAY' },
            {
              name: 'contractTermMonthsC',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'newLicence', displayStatus: 'ON_DISPLAY' },
            {
              name: 'newService',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'abrConverted', displayStatus: 'ON_DISPLAY' },
            {
              name: 'leadSource',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'serviceDeliveredByAgencyC', displayStatus: 'ON_DISPLAY' },
            {
              name: 'newMultiyearBonus',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'outboundDealBonus', displayStatus: 'ON_DISPLAY' },
            {
              name: 'agencyTransferBonus',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'licenceAccelerator', displayStatus: 'ON_DISPLAY' },
            {
              name: 'serviceDecelerator',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'newAeFinalAbr', displayStatus: 'ON_DISPLAY' },
          ],
          filterToDisplayId: '38151bc7-a42f-4190-99f6-0bf672fe6c17',
          planRuleAssignmentId: '223721bd-e7d9-4d33-9f46-7ef06e7cf6f6',
        },
      ],
      highlightedKpis: [
        {
          identifier: 'secondary',
          variableId: '64106d44-83ec-4678-80db-72c40a421c24',
          maximumVariableId: '61a6a183-8657-4185-b364-701b8c49948c',
          minimumVariableId: null,
        },
        {
          identifier: 'primary',
          variableId: '0a1eb2e3-ea14-4ff1-b0d5-e9641ed143dc',
          maximumVariableId: '60efc1f3-9088-4a07-9b7a-7e37da241d0f',
          minimumVariableId: null,
        },
      ],
    },
    filters: {
      closedInQuarter: {
        id: '38151bc7-a42f-4190-99f6-0bf672fe6c17',
        name: 'Closed In Quarter',
        machineName: 'closedInQuarter',
        customObjectDefinitionMachineName: 'opportunity',
        condition: 'opportunity.closeDate > 1000 AND opportunity.ownerId = "swag" AND opportunity.isWon = true',
      },
    },
    variables: {
      bonus: {
        id: '60efc1f3-9088-4a07-9b7a-7e37da241d0f',
        name: 'Bonus',
        type: 'user',
        format: 'currency',
        formula: '',
        machineName: 'bonus',
      },
      target: {
        id: '61a6a183-8657-4185-b364-701b8c49948c',
        name: 'Target',
        type: 'user',
        format: 'currency',
        formula: '',
        machineName: 'target',
      },
      licenceRn: {
        id: 'e5567c5e-1b07-49f9-a1c3-62c95f51212a',
        name: 'Licence RN',
        type: 'object',
        format: 'currency',
        formula: 'convertCurrency(opportunity.licenceRnNc, opportunity.currencyIsoCode, user.currency)',
        machineName: 'licenceRn',
      },
      serviceNb: {
        id: '0245257e-5f1d-4f00-87b9-9d44d7fd957b',
        name: 'Service NB',
        type: 'object',
        format: 'currency',
        formula: 'convertCurrency(opportunity.serviceNbNc, opportunity.currencyIsoCode, user.currency)',
        machineName: 'serviceNb',
      },
      newLicence: {
        id: 'e5846e5b-3811-4b89-b6f2-0081d311a211',
        name: 'New Licence',
        type: 'object',
        format: 'currency',
        formula: 'opportunity.licenceRn - opportunity.previousLicence',
        machineName: 'newLicence',
      },
      licenceRnNc: {
        id: '8dfa453f-a12b-41b2-86d9-2536f184e61d',
        name: 'Licence RN NC',
        type: 'object',
        format: 'currency',
        formula:
          'SUM(opportunity.opportunityLineItems, IF(opportunityLineItem.opportunityStageC = "6 - Closed Won", IF(CONTAINS(statement.newProductLicenceMapping, opportunityLineItem.magentrixOneProductNameMgtrxC) = true, opportunityLineItem.totalPrice / opportunityLineItem.contractTermMonthsC * 12, 0), 0))',
        machineName: 'licenceRnNc',
      },
      serviceNbNc: {
        id: '19cc9dcb-0581-4d0b-af66-714f3c9d1ccc',
        name: 'Service NB NC',
        type: 'object',
        format: 'currency',
        formula:
          'SUM(opportunity.opportunityLineItems, IF(opportunityLineItem.opportunityStageC = "6 - Closed Won", IF(CONTAINS(statement.newProductServiceMapping, opportunityLineItem.magentrixOneProductNameMgtrxC) = true, IF(opportunityLineItem.contractTermMonthsC > 12, opportunityLineItem.totalPrice / opportunityLineItem.contractTermMonthsC * 12, opportunityLineItem.totalPrice), 0), 0))',
        machineName: 'serviceNbNc',
      },
      abrConverted: {
        id: '3e058443-7ba8-49fe-9758-f9438427dd9b',
        name: 'ABR Converted',
        type: 'object',
        format: 'currency',
        formula: 'convertCurrency(opportunity.jppAmountC, opportunity.currencyIsoCode, user.currency)',
        machineName: 'abrConverted',
      },
      newAeFinalAbr: {
        id: 'c4bd50bb-7e4d-4aa9-84b1-4d5d8560c171',
        name: 'New AE Final ABR',
        type: 'object',
        format: 'currency',
        formula:
          'opportunity.abrConverted + opportunity.newMultiyearBonus + opportunity.outboundDealBonus + opportunity.agencyTransferBonus + opportunity.licenceAccelerator + opportunity.serviceDecelerator',
        machineName: 'newAeFinalAbr',
      },
      newSalesCurve: {
        id: '9b137b43-296f-4807-8c95-7768a520cda0',
        name: 'New Sales Curve',
        type: 'statement',
        format: 'table',
        formula:
          '[[-Infinity, 0, 0], [0, 0.05, 0], [0.05, 0.1, 0.03], [0.1, 0.15, 0.07], [0.15, 0.2, 0.1], [0.2, 0.25, 0.13], [0.25, 0.3, 0.17], [0.3, 0.35, 0.2], [0.35, 0.4, 0.23], [0.4, 0.45, 0.27], [0.45, 0.5, 0.3], [0.5, 0.55, 0.33], [0.55, 0.6, 0.4], [0.6, 0.65, 0.47], [0.65, 0.7, 0.53], [0.7, 0.75, 0.6], [0.75, 0.8, 0.67], [0.8, 0.85, 0.73], [0.85, 0.9, 0.8], [0.9, 0.95, 0.87], [0.95, 1, 0.93], [1, 1.05, 1], [1.05, 1.1, 1.08], [1.1, 1.15, 1.15], [1.15, 1.2, 1.23], [1.2, 1.25, 1.3], [1.25, 1.3, 1.38], [1.3, 1.35, 1.45], [1.35, 1.4, 1.53], [1.4, 1.45, 1.6], [1.45, 1.5, 1.68], [1.5, 1.55, 1.75], [1.55, 1.6, 1.83], [1.6, 1.65, 1.9], [1.65, 1.7, 1.98], [1.7, 1.75, 2.05], [1.75, 1.8, 2.13], [1.8, 1.85, 2.2], [1.85, 1.9, 2.28], [1.9, 1.95, 2.35], [1.95, 2, 2.43], [2, Infinity, 2.5]]',
        machineName: 'newSalesCurve',
      },
      previousLicence: {
        id: 'b3409e9e-8eb7-4fc3-93c2-9cdbd039b491',
        name: 'Previous Licence',
        type: 'object',
        format: 'currency',
        formula: 'IF(opportunity.previousOpportunity, opportunity.previousOpportunity.licenceRn, 0)',
        machineName: 'previousLicence',
      },
      newAeAchievement: {
        id: 'aa04e6ae-8206-477a-af49-309b77cf0c1c',
        name: 'New AE Achievement',
        type: 'statement',
        format: 'percent',
        formula: 'LINEAR(statement.newAeTargetReach, statement.newSalesCurve)',
        machineName: 'newAeAchievement',
      },
      newAeTargetReach: {
        id: '0c54518c-6c07-43e3-808a-bf94e9f45bfd',
        name: 'New AE Target Reach',
        type: 'statement',
        format: 'percent',
        formula: 'floor(statement.newAeFinalAbrBonusBase / user.target * 100) / 100',
        machineName: 'newAeTargetReach',
      },
      newMultiyearBonus: {
        id: '3848cf96-fe46-48fb-acd0-7338eb55ed95',
        name: 'New Multiyear Bonus',
        type: 'object',
        format: 'currency',
        formula:
          'IF(opportunity.contractTermMonthsC = 24, IF(opportunity.abrConverted > 60000, 0.25 * opportunity.abrConverted, IF(opportunity.abrConverted > 20000, 0.2 * opportunity.abrConverted, 0)), 0) + IF(opportunity.contractTermMonthsC = 36, IF(opportunity.abrConverted > 60000, 0.35 * opportunity.abrConverted, IF(opportunity.abrConverted > 20000, 0.3 * opportunity.abrConverted, 0)), 0)',
        machineName: 'newMultiyearBonus',
      },
      outboundDealBonus: {
        id: 'f2b51ac0-add3-41dc-893c-e541fdfe37a4',
        name: 'Outbound Deal Bonus',
        type: 'object',
        format: 'currency',
        formula: 'IF(opportunity.leadSource = "Outbound", opportunity.newLicence * 0.2, 0)',
        machineName: 'outboundDealBonus',
      },
      licenceAccelerator: {
        id: '59255fc1-283e-4922-8f4b-d9d9af410e93',
        name: 'Licence Accelerator',
        type: 'object',
        format: 'currency',
        formula: 'opportunity.newLicence * 0.3',
        machineName: 'licenceAccelerator',
      },
      serviceDecelerator: {
        id: '29d2f572-39c3-4e0c-a077-fb858752be38',
        name: 'Service Decelerator',
        type: 'object',
        format: 'currency',
        formula: '-opportunity.serviceNb * 0.9',
        machineName: 'serviceDecelerator',
      },
      agencyTransferBonus: {
        id: '7f9a4537-4478-4774-8231-d2200651a87a',
        name: 'Agency Transfer Bonus',
        type: 'object',
        format: 'currency',
        formula: 'IF(opportunity.serviceDeliveredByAgencyC = true, opportunity.newLicence * 0.1, 0)',
        machineName: 'agencyTransferBonus',
      },
      newAeFinalAbrBonusBase: {
        id: '64106d44-83ec-4678-80db-72c40a421c24',
        name: 'New AE Final ABR Bonus Base',
        type: 'statement',
        format: 'currency',
        formula: 'SUM(filter.closedInQuarter, opportunity.newAeFinalAbr)',
        machineName: 'newAeFinalAbrBonusBase',
      },
      newAeFinalBonusQuarter: {
        id: '0a1eb2e3-ea14-4ff1-b0d5-e9641ed143dc',
        name: 'New AE Final Bonus Quarter',
        type: 'statement',
        format: 'currency',
        formula: 'statement.newAeAchievement * user.bonus',
        machineName: 'newAeFinalBonusQuarter',
      },
      newProductLicenceMapping: {
        id: '9fe3a232-26a3-4c03-aef3-3f663ca9c182',
        name: 'New Product Licence Mapping',
        type: 'statement',
        format: 'table',
        formula:
          '["Licence - Enterprise Web", "License - Elite", "License - Essentials", "License - Growth", "License AB Tasty - Elite", "License AB Tasty - Essentials", "License AB Tasty - Growth", "License Flagship - Elite", "License Flagship - Essentials", "License Flagship - Growth", "Module - Data Activation", "Module - Dynamic Widgets (Revenue Share Contract)", "Module - Server Side", "Module - User Insights", "Module - UX Analytics", "Option - DMP Connector", "Option - NPS", "Option - Session Recording"]',
        machineName: 'newProductLicenceMapping',
      },
      newProductServiceMapping: {
        id: 'eeae7566-a688-4bbc-b5ce-c117864959c2',
        name: 'New Product Service Mapping',
        type: 'statement',
        format: 'table',
        formula:
          '["Ongoing Training", "Ongoing Training(On Site)", "Ongoing Training(Open)", "Ongoing Training(Webinar)", "Professional Services - 4 regular campaigns(One time)", "Professional Services - Analysis Pack(5 Campaigns)", "Professional Services - Migration booster(10 regular campaigns)", "Professional Services - Recurring implementation(2 regular campaigns)", "Professional Services - Recurring implementation(4 regular campaigns)", "Professional Services - Urgent implementation(2 regular campaigns)", "Support - Days (8h) of support/month", "Support - Pay as you go", "Workshop / (CRO Academy(2 workshops))", "Workshop / (CRO Academy(4 workshops))", "Workshop / (CRO Academy(6 workshops))"]',
        machineName: 'newProductServiceMapping',
      },
    },
    customObjects: {
      opportunity: {
        id: '860ec547-66dc-4837-b303-d214bebe60a2',
        name: 'Opportunity',
        type: CustomObjectDefinitionType.SALESFORCE,
        properties: {
          id: {
            name: 'Opportunity ID',
            machineName: 'id',
            format: FormatsEnum.text,
            isRequired: true,
          },
          name: {
            name: 'Name',
            machineName: 'name',
            format: FormatsEnum.text,
            isRequired: false,
          },
          type: {
            name: 'Opportunity Type',
            machineName: 'type',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isWon: {
            name: 'Won',
            machineName: 'isWon',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          amount: {
            name: 'Amount',
            machineName: 'amount',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          officeC: {
            name: 'Opportunity Office',
            machineName: 'officeC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          ownerId: {
            name: 'Owner ID',
            machineName: 'ownerId',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isClosed: {
            name: 'Closed',
            machineName: 'isClosed',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          paymentC: {
            name: 'Invoicing Frequency',
            machineName: 'paymentC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          accountId: {
            name: 'Account ID',
            machineName: 'accountId',
            format: FormatsEnum.text,
            isRequired: false,
          },
          closeDate: {
            name: 'Close Date',
            machineName: 'closeDate',
            format: FormatsEnum.date,
            isRequired: false,
          },
          stageName: {
            name: 'Stage',
            machineName: 'stageName',
            format: FormatsEnum.text,
            isRequired: false,
          },
          expansionC: {
            name: 'Expansion',
            machineName: 'expansionC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          jppAmountC: {
            name: 'ABR',
            machineName: 'jppAmountC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          leadSource: {
            name: 'Lead Source',
            machineName: 'leadSource',
            format: FormatsEnum.text,
            isRequired: false,
          },
          createdDate: {
            name: 'Created Date',
            machineName: 'createdDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          arrActualsFC: {
            name: 'ARR Actuals (f)',
            machineName: 'arrActualsFC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          churnReasonC: {
            name: 'Churn Reason',
            machineName: 'churnReasonC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          licenceMrr2C: {
            name: 'Licence MRR',
            machineName: 'licenceMrr2C',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          supportMrr2C: {
            name: 'Support MRR',
            machineName: 'supportMrr2C',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          currencyIsoCode: {
            name: 'Opportunity Currency',
            machineName: 'currencyIsoCode',
            format: FormatsEnum.text,
            isRequired: false,
          },
          lastModifiedDate: {
            name: 'Last Modified Date',
            machineName: 'lastModifiedDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          businessChurnArrC: {
            name: 'Business Churn ARR',
            machineName: 'businessChurnArrC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          previousOppsNameC: {
            name: 'Previous Opportunity Name',
            machineName: 'previousOppsNameC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          contractTermMonthsC: {
            name: 'Contract Term (months)',
            machineName: 'contractTermMonthsC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          annualContractValueC: {
            name: 'Annual Contract Value',
            machineName: 'annualContractValueC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          previousOpportunityIdC: {
            name: 'Previous Opportunity ID',
            machineName: 'previousOpportunityIdC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          sofactoappPeriodiciteC: {
            name: 'Frequency',
            machineName: 'sofactoappPeriodiciteC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          annualRecurringRevenueC: {
            name: 'Annual Recurring Revenue',
            machineName: 'annualRecurringRevenueC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          previousContractEndDateC: {
            name: 'Previous Contract End Date',
            machineName: 'previousContractEndDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          serviceDeliveredByAgencyC: {
            name: 'Service Delivered By Agency',
            machineName: 'serviceDeliveredByAgencyC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          renewedSubscriptionsAmountC: {
            name: 'Renewed Subscriptions Amount',
            machineName: 'renewedSubscriptionsAmountC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
        },
        schema: {
          $id: 'http://amalia.io/opportunity.schema.json',
          type: 'object',
          title: 'Opportunity',
          $schema: 'http://json-schema.org/draft-07/schema#',
          required: ['id'],
          properties: {
            id: {
              $id: '#/properties/id',
              type: 'string',
              title: 'Id',
              description: 'Opportunity ID',
            },
            name: {
              $id: '#/properties/name',
              type: ['string', 'null'],
              title: 'Name',
              default: null,
              description: 'Name',
            },
            type: {
              $id: '#/properties/type',
              type: ['string', 'null'],
              title: 'Type',
              default: null,
              description: 'Opportunity Type',
            },
            isWon: {
              $id: '#/properties/isWon',
              type: ['boolean', 'null'],
              title: 'IsWon',
              default: null,
              description: 'Won',
            },
            amount: {
              $id: '#/properties/amount',
              $ref: '#/definitions/currency',
              title: 'Amount',
              description: 'Amount',
            },
            officeC: {
              $id: '#/properties/officeC',
              type: ['string', 'null'],
              title: 'Office__c',
              default: null,
              description: 'Opportunity Office',
            },
            ownerId: {
              $id: '#/properties/ownerId',
              type: ['string', 'null'],
              title: 'OwnerId',
              default: null,
              description: 'Owner ID',
            },
            isClosed: {
              $id: '#/properties/isClosed',
              type: ['boolean', 'null'],
              title: 'IsClosed',
              default: null,
              description: 'Closed',
            },
            paymentC: {
              $id: '#/properties/paymentC',
              type: ['string', 'null'],
              title: 'Payment__c',
              default: null,
              description: 'Invoicing Frequency',
            },
            accountId: {
              $id: '#/properties/accountId',
              type: ['string', 'null'],
              title: 'AccountId',
              default: null,
              description: 'Account ID',
            },
            closeDate: {
              $id: '#/properties/closeDate',
              type: ['string', 'null'],
              title: 'CloseDate',
              format: 'date',
              default: null,
              description: 'Close Date',
            },
            stageName: {
              $id: '#/properties/stageName',
              type: ['string', 'null'],
              title: 'StageName',
              default: null,
              description: 'Stage',
            },
            expansionC: {
              $id: '#/properties/expansionC',
              type: ['boolean', 'null'],
              title: 'Expansion__c',
              default: null,
              description: 'Expansion',
            },
            jppAmountC: {
              $id: '#/properties/jppAmountC',
              $ref: '#/definitions/currency',
              title: 'JPP_Amount__c',
              description: 'ABR',
            },
            leadSource: {
              $id: '#/properties/leadSource',
              type: ['string', 'null'],
              title: 'LeadSource',
              default: null,
              description: 'Lead Source',
            },
            createdDate: {
              $id: '#/properties/createdDate',
              type: ['string', 'null'],
              title: 'CreatedDate',
              format: 'date-time',
              default: null,
              description: 'Created Date',
            },
            arrActualsFC: {
              $id: '#/properties/arrActualsFC',
              $ref: '#/definitions/currency',
              title: 'ARRActuals_f__c',
              description: 'ARR Actuals (f)',
            },
            churnReasonC: {
              $id: '#/properties/churnReasonC',
              type: ['string', 'null'],
              title: 'Churn_reason__c',
              default: null,
              description: 'Churn Reason',
            },
            licenceMrr2C: {
              $id: '#/properties/licenceMrr2C',
              $ref: '#/definitions/currency',
              title: 'Licence_MRR2__c',
              description: 'Licence MRR',
            },
            supportMrr2C: {
              $id: '#/properties/supportMrr2C',
              $ref: '#/definitions/currency',
              title: 'Support_MRR2__c',
              description: 'Support MRR',
            },
            currencyIsoCode: {
              $id: '#/properties/currencyIsoCode',
              type: ['string', 'null'],
              title: 'CurrencyIsoCode',
              default: null,
              description: 'Opportunity Currency',
            },
            lastModifiedDate: {
              $id: '#/properties/lastModifiedDate',
              type: ['string', 'null'],
              title: 'LastModifiedDate',
              format: 'date-time',
              default: null,
              description: 'Last Modified Date',
            },
            businessChurnArrC: {
              $id: '#/properties/businessChurnArrC',
              $ref: '#/definitions/currency',
              title: 'Business_Churn_ARR__c',
              description: 'Business Churn ARR',
            },
            previousOppsNameC: {
              $id: '#/properties/previousOppsNameC',
              type: ['string', 'null'],
              title: 'Previous_opps_name__c',
              default: null,
              description: 'Previous Opportunity Name',
            },
            contractTermMonthsC: {
              $id: '#/properties/contractTermMonthsC',
              type: ['number', 'null'],
              title: 'Contract_Term_months__c',
              default: null,
              description: 'Contract Term (months)',
            },
            annualContractValueC: {
              $id: '#/properties/annualContractValueC',
              $ref: '#/definitions/currency',
              title: 'Annual_Contract_Value__c',
              description: 'Annual Contract Value',
            },
            previousOpportunityIdC: {
              $id: '#/properties/previousOpportunityIdC',
              type: ['string', 'null'],
              title: 'Previous_Opportunity_ID__c',
              default: null,
              description: 'Previous Opportunity ID',
            },
            sofactoappPeriodiciteC: {
              $id: '#/properties/sofactoappPeriodiciteC',
              type: ['string', 'null'],
              title: 'sofactoapp__Periodicite__c',
              default: null,
              description: 'Frequency',
            },
            annualRecurringRevenueC: {
              $id: '#/properties/annualRecurringRevenueC',
              $ref: '#/definitions/currency',
              title: 'Annual_Recurring_Revenue__c',
              description: 'Annual Recurring Revenue',
            },
            previousContractEndDateC: {
              $id: '#/properties/previousContractEndDateC',
              type: ['string', 'null'],
              title: 'Previous_Contract_End_Date__c',
              format: 'date',
              default: null,
              description: 'Previous Contract End Date',
            },
            serviceDeliveredByAgencyC: {
              $id: '#/properties/serviceDeliveredByAgencyC',
              type: ['boolean', 'null'],
              title: 'Service_Delivered_By_Agency__c',
              default: null,
              description: 'Service Delivered By Agency',
            },
            renewedSubscriptionsAmountC: {
              $id: '#/properties/renewedSubscriptionsAmountC',
              $ref: '#/definitions/currency',
              title: 'Renewed_Subscriptions_Amount__c',
              description: 'Renewed Subscriptions Amount',
            },
          },
          definitions: {
            currency: {
              type: 'object',
              properties: {
                value: { type: ['number', 'null'], default: null },
                symbol: {
                  enum: [
                    'AED',
                    'AFN',
                    'ALL',
                    'AMD',
                    'ANG',
                    'AOA',
                    'ARS',
                    'AUD',
                    'AWG',
                    'AZN',
                    'BAM',
                    'BBD',
                    'BDT',
                    'BGN',
                    'BHD',
                    'BIF',
                    'BMD',
                    'BND',
                    'BOB',
                    'BRL',
                    'BSD',
                    'BTC',
                    'BTN',
                    'BWP',
                    'BYN',
                    'BYR',
                    'BZD',
                    'CAD',
                    'CDF',
                    'CHF',
                    'CLF',
                    'CLP',
                    'CNY',
                    'COP',
                    'CRC',
                    'CUC',
                    'CUP',
                    'CVE',
                    'CZK',
                    'DJF',
                    'DKK',
                    'DOP',
                    'DZD',
                    'EGP',
                    'ERN',
                    'ETB',
                    'EUR',
                    'FJD',
                    'FKP',
                    'GBP',
                    'GEL',
                    'GGP',
                    'GHS',
                    'GIP',
                    'GMD',
                    'GNF',
                    'GTQ',
                    'GYD',
                    'HKD',
                    'HNL',
                    'HRK',
                    'HTG',
                    'HUF',
                    'IDR',
                    'ILS',
                    'IMP',
                    'INR',
                    'IQD',
                    'IRR',
                    'ISK',
                    'JEP',
                    'JMD',
                    'JOD',
                    'JPY',
                    'KES',
                    'KGS',
                    'KHR',
                    'KMF',
                    'KPW',
                    'KRW',
                    'KWD',
                    'KYD',
                    'KZT',
                    'LAK',
                    'LBP',
                    'LKR',
                    'LRD',
                    'LSL',
                    'LTL',
                    'LVL',
                    'LYD',
                    'MAD',
                    'MDL',
                    'MGA',
                    'MKD',
                    'MMK',
                    'MNT',
                    'MOP',
                    'MRO',
                    'MUR',
                    'MVR',
                    'MWK',
                    'MXN',
                    'MYR',
                    'MZN',
                    'NAD',
                    'NGN',
                    'NIO',
                    'NOK',
                    'NPR',
                    'NZD',
                    'OMR',
                    'PAB',
                    'PEN',
                    'PGK',
                    'PHP',
                    'PKR',
                    'PLN',
                    'PYG',
                    'QAR',
                    'RON',
                    'RSD',
                    'RUB',
                    'RWF',
                    'SAR',
                    'SBD',
                    'SCR',
                    'SDG',
                    'SEK',
                    'SGD',
                    'SHP',
                    'SLL',
                    'SOS',
                    'SRD',
                    'STD',
                    'SVC',
                    'SYP',
                    'SZL',
                    'THB',
                    'TJS',
                    'TMT',
                    'TND',
                    'TOP',
                    'TRY',
                    'TTD',
                    'TWD',
                    'TZS',
                    'UAH',
                    'UGX',
                    'USD',
                    'UYU',
                    'UZS',
                    'VEF',
                    'VND',
                    'VUV',
                    'WST',
                    'XAF',
                    'XAG',
                    'XAU',
                    'XCD',
                    'XDR',
                    'XOF',
                    'XPF',
                    'YER',
                    'ZAR',
                    'ZMK',
                    'ZMW',
                    'ZWL',
                  ],
                  type: 'string',
                  default: 'EUR',
                },
              },
            },
          },
          description: '',
          additionalProperties: false,
        },
        createdAt: '2020-08-26T13:52:22.265Z',
        updatedAt: '2020-12-13T23:00:03.864Z',
        externalIds: ['id'],
        machineName: 'opportunity',
        preferences: { duplicates: 'overwrite' },
      },
      opportunityLineItem: {
        id: '469c3862-21d2-4005-b663-67c7e0e7b0cf',
        name: 'OpportunityLineItem',
        type: CustomObjectDefinitionType.SALESFORCE,
        properties: {
          id: {
            name: 'Line Item ID',
            machineName: 'id',
            format: FormatsEnum.text,
            isRequired: true,
          },
          name: {
            name: 'Opportunity Product Name',
            machineName: 'name',
            format: FormatsEnum.text,
            isRequired: false,
          },
          flagC: {
            name: 'Flag',
            machineName: 'flagC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          assetC: {
            name: 'Asset?',
            machineName: 'assetC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          discount: {
            name: 'Discount',
            machineName: 'discount',
            format: FormatsEnum.number,
            isRequired: false,
          },
          quantity: {
            name: 'Quantity',
            machineName: 'quantity',
            format: FormatsEnum.number,
            isRequired: false,
          },
          renewalC: {
            name: 'Renewal',
            machineName: 'renewalC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          supportC: {
            name: 'Support',
            machineName: 'supportC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          abFamilyC: {
            name: 'AB Family',
            machineName: 'abFamilyC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isDeleted: {
            name: 'Deleted',
            machineName: 'isDeleted',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          listPrice: {
            name: 'List Price',
            machineName: 'listPrice',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          sortOrder: {
            name: 'Sort Order',
            machineName: 'sortOrder',
            format: FormatsEnum.number,
            isRequired: false,
          },
          unitPrice: {
            name: 'Sales Price',
            machineName: 'unitPrice',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          isSupportC: {
            name: 'Is Support',
            machineName: 'isSupportC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          product2Id: {
            name: 'Product ID',
            machineName: 'product2Id',
            format: FormatsEnum.text,
            isRequired: false,
          },
          totalPrice: {
            name: 'Total Price',
            machineName: 'totalPrice',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          createdById: {
            name: 'Created By ID',
            machineName: 'createdById',
            format: FormatsEnum.text,
            isRequired: false,
          },
          createdDate: {
            name: 'Created Date',
            machineName: 'createdDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          description: {
            name: 'Line Description',
            machineName: 'description',
            format: FormatsEnum.text,
            isRequired: false,
          },
          productCode: {
            name: 'Product Code',
            machineName: 'productCode',
            format: FormatsEnum.text,
            isRequired: false,
          },
          serviceDate: {
            name: 'Date',
            machineName: 'serviceDate',
            format: FormatsEnum.date,
            isRequired: false,
          },
          opportunityC: {
            name: 'Opportunity',
            machineName: 'opportunityC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          productTermC: {
            name: 'Product Term',
            machineName: 'productTermC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          supportDaysC: {
            name: 'Support days',
            machineName: 'supportDaysC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          businessTypeC: {
            name: 'Sales Type',
            machineName: 'businessTypeC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          monthlyPriceC: {
            name: 'Monthly Price',
            machineName: 'monthlyPriceC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          opportunityId: {
            name: 'Opportunity ID',
            machineName: 'opportunityId',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isLicenceOnlyC: {
            name: 'Is Licence Only',
            machineName: 'isLicenceOnlyC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          systemModstamp: {
            name: 'System Modstamp',
            machineName: 'systemModstamp',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          currencyIsoCode: {
            name: 'Currency ISO Code',
            machineName: 'currencyIsoCode',
            format: FormatsEnum.text,
            isRequired: false,
          },
          monthlySupportC: {
            name: 'Monthly Support',
            machineName: 'monthlySupportC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          contractEndDateC: {
            name: 'Contract End Date',
            machineName: 'contractEndDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          flagshipProductC: {
            name: 'Flagship Product',
            machineName: 'flagshipProductC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          lastModifiedById: {
            name: 'Last Modified By ID',
            machineName: 'lastModifiedById',
            format: FormatsEnum.text,
            isRequired: false,
          },
          lastModifiedDate: {
            name: 'Last Modified Date',
            machineName: 'lastModifiedDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          renewalVarianceC: {
            name: 'Renewal Variance (%)',
            machineName: 'renewalVarianceC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          opportunityStageC: {
            name: 'Opportunity Stage',
            machineName: 'opportunityStageC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          contractStartDateC: {
            name: 'Contract Start Date',
            machineName: 'contractStartDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          isModuleOrOptionsC: {
            name: 'Is Module or Options',
            machineName: 'isModuleOrOptionsC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          contractTermMonthsC: {
            name: 'Contract Term (months)',
            machineName: 'contractTermMonthsC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          expectedTotalPriceC: {
            name: 'Expected Total Price',
            machineName: 'expectedTotalPriceC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          ongoingOpportunityC: {
            name: 'Ongoing Opportunity',
            machineName: 'ongoingOpportunityC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          sofactoappAmountVatC: {
            name: 'Total Amount incl. VAT',
            machineName: 'sofactoappAmountVatC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          supportMonthlyPriceC: {
            name: 'Support Monthly Price',
            machineName: 'supportMonthlyPriceC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          monthlyPriceQuantityC: {
            name: 'Net Monthly Price',
            machineName: 'monthlyPriceQuantityC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          sofactoTempStartDateC: {
            name: 'Sofacto Temp Start Date',
            machineName: 'sofactoTempStartDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          numberOfDaysOfSupportC: {
            name: 'Number of Days of Support',
            machineName: 'numberOfDaysOfSupportC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          opportunityProductMrrC: {
            name: 'Opportunity Product MRR',
            machineName: 'opportunityProductMrrC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          magentrixOneProductNameMgtrxC: {
            name: 'Product Name',
            machineName: 'magentrixOneProductNameMgtrxC',
            format: FormatsEnum.text,
            isRequired: false,
          },
        },
        schema: {
          $id: 'http://amalia.io/opportunityLineItem.schema.json',
          type: 'object',
          title: 'OpportunityLineItem',
          $schema: 'http://json-schema.org/draft-07/schema#',
          required: ['id'],
          properties: {
            id: {
              $id: '#/properties/id',
              type: 'string',
              title: 'Id',
              description: 'Line Item ID',
            },
            name: {
              $id: '#/properties/name',
              type: ['string', 'null'],
              title: 'Name',
              default: null,
              description: 'Opportunity Product Name',
            },
            flagC: {
              $id: '#/properties/flagC',
              type: ['string', 'null'],
              title: 'Flag__c',
              default: null,
              description: 'Flag',
            },
            assetC: {
              $id: '#/properties/assetC',
              type: ['boolean', 'null'],
              title: 'Asset__c',
              default: null,
              description: 'Asset?',
            },
            discount: {
              $id: '#/properties/discount',
              type: ['number', 'null'],
              title: 'Discount',
              default: null,
              description: 'Discount',
            },
            quantity: {
              $id: '#/properties/quantity',
              type: ['number', 'null'],
              title: 'Quantity',
              default: null,
              description: 'Quantity',
            },
            renewalC: {
              $id: '#/properties/renewalC',
              type: ['string', 'null'],
              title: 'Renewal__c',
              default: null,
              description: 'Renewal',
            },
            supportC: {
              $id: '#/properties/supportC',
              type: ['number', 'null'],
              title: 'Support__c',
              default: null,
              description: 'Support',
            },
            abFamilyC: {
              $id: '#/properties/abFamilyC',
              type: ['string', 'null'],
              title: 'AB_Family__c',
              default: null,
              description: 'AB Family',
            },
            isDeleted: {
              $id: '#/properties/isDeleted',
              type: ['boolean', 'null'],
              title: 'IsDeleted',
              default: null,
              description: 'Deleted',
            },
            listPrice: {
              $id: '#/properties/listPrice',
              $ref: '#/definitions/currency',
              title: 'ListPrice',
              description: 'List Price',
            },
            sortOrder: {
              $id: '#/properties/sortOrder',
              type: ['number', 'null'],
              title: 'SortOrder',
              default: null,
              description: 'Sort Order',
            },
            unitPrice: {
              $id: '#/properties/unitPrice',
              $ref: '#/definitions/currency',
              title: 'UnitPrice',
              description: 'Sales Price',
            },
            isSupportC: {
              $id: '#/properties/isSupportC',
              type: ['boolean', 'null'],
              title: 'Is_Support__c',
              default: null,
              description: 'Is Support',
            },
            product2Id: {
              $id: '#/properties/product2Id',
              type: ['string', 'null'],
              title: 'Product2Id',
              default: null,
              description: 'Product ID',
            },
            totalPrice: {
              $id: '#/properties/totalPrice',
              $ref: '#/definitions/currency',
              title: 'TotalPrice',
              description: 'Total Price',
            },
            createdById: {
              $id: '#/properties/createdById',
              type: ['string', 'null'],
              title: 'CreatedById',
              default: null,
              description: 'Created By ID',
            },
            createdDate: {
              $id: '#/properties/createdDate',
              type: ['string', 'null'],
              title: 'CreatedDate',
              format: 'date-time',
              default: null,
              description: 'Created Date',
            },
            description: {
              $id: '#/properties/description',
              type: ['string', 'null'],
              title: 'Description',
              default: null,
              description: 'Line Description',
            },
            productCode: {
              $id: '#/properties/productCode',
              type: ['string', 'null'],
              title: 'ProductCode',
              default: null,
              description: 'Product Code',
            },
            serviceDate: {
              $id: '#/properties/serviceDate',
              type: ['string', 'null'],
              title: 'ServiceDate',
              format: 'date',
              default: null,
              description: 'Date',
            },
            opportunityC: {
              $id: '#/properties/opportunityC',
              type: ['string', 'null'],
              title: 'Opportunity__c',
              default: null,
              description: 'Opportunity',
            },
            productTermC: {
              $id: '#/properties/productTermC',
              type: ['number', 'null'],
              title: 'Product_Term__c',
              default: null,
              description: 'Product Term',
            },
            supportDaysC: {
              $id: '#/properties/supportDaysC',
              type: ['number', 'null'],
              title: 'Support_days__c',
              default: null,
              description: 'Support days',
            },
            businessTypeC: {
              $id: '#/properties/businessTypeC',
              type: ['string', 'null'],
              title: 'Business_Type__c',
              default: null,
              description: 'Sales Type',
            },
            monthlyPriceC: {
              $id: '#/properties/monthlyPriceC',
              $ref: '#/definitions/currency',
              title: 'Monthly_Price__c',
              description: 'Monthly Price',
            },
            opportunityId: {
              $id: '#/properties/opportunityId',
              type: ['string', 'null'],
              title: 'OpportunityId',
              default: null,
              description: 'Opportunity ID',
            },
            isLicenceOnlyC: {
              $id: '#/properties/isLicenceOnlyC',
              type: ['boolean', 'null'],
              title: 'Is_Licence_Only__c',
              default: null,
              description: 'Is Licence Only',
            },
            systemModstamp: {
              $id: '#/properties/systemModstamp',
              type: ['string', 'null'],
              title: 'SystemModstamp',
              format: 'date-time',
              default: null,
              description: 'System Modstamp',
            },
            currencyIsoCode: {
              $id: '#/properties/currencyIsoCode',
              type: ['string', 'null'],
              title: 'CurrencyIsoCode',
              default: null,
              description: 'Currency ISO Code',
            },
            monthlySupportC: {
              $id: '#/properties/monthlySupportC',
              type: ['number', 'null'],
              title: 'Monthly_Support__c',
              default: null,
              description: 'Monthly Support',
            },
            contractEndDateC: {
              $id: '#/properties/contractEndDateC',
              type: ['string', 'null'],
              title: 'Contract_End_Date__c',
              format: 'date',
              default: null,
              description: 'Contract End Date',
            },
            flagshipProductC: {
              $id: '#/properties/flagshipProductC',
              type: ['boolean', 'null'],
              title: 'Flagship_Product__c',
              default: null,
              description: 'Flagship Product',
            },
            lastModifiedById: {
              $id: '#/properties/lastModifiedById',
              type: ['string', 'null'],
              title: 'LastModifiedById',
              default: null,
              description: 'Last Modified By ID',
            },
            lastModifiedDate: {
              $id: '#/properties/lastModifiedDate',
              type: ['string', 'null'],
              title: 'LastModifiedDate',
              format: 'date-time',
              default: null,
              description: 'Last Modified Date',
            },
            renewalVarianceC: {
              $id: '#/properties/renewalVarianceC',
              type: ['number', 'null'],
              title: 'Renewal_Variance__c',
              default: null,
              description: 'Renewal Variance (%)',
            },
            opportunityStageC: {
              $id: '#/properties/opportunityStageC',
              type: ['string', 'null'],
              title: 'Opportunity_Stage__c',
              default: null,
              description: 'Opportunity Stage',
            },
            contractStartDateC: {
              $id: '#/properties/contractStartDateC',
              type: ['string', 'null'],
              title: 'Contract_Start_Date__c',
              format: 'date',
              default: null,
              description: 'Contract Start Date',
            },
            isModuleOrOptionsC: {
              $id: '#/properties/isModuleOrOptionsC',
              type: ['boolean', 'null'],
              title: 'Is_Module_or_Options__c',
              default: null,
              description: 'Is Module or Options',
            },
            contractTermMonthsC: {
              $id: '#/properties/contractTermMonthsC',
              type: ['number', 'null'],
              title: 'Contract_Term_months__c',
              default: null,
              description: 'Contract Term (months)',
            },
            expectedTotalPriceC: {
              $id: '#/properties/expectedTotalPriceC',
              $ref: '#/definitions/currency',
              title: 'Expected_Total_Price__c',
              description: 'Expected Total Price',
            },
            ongoingOpportunityC: {
              $id: '#/properties/ongoingOpportunityC',
              type: ['boolean', 'null'],
              title: 'Ongoing_Opportunity__c',
              default: null,
              description: 'Ongoing Opportunity',
            },
            sofactoappAmountVatC: {
              $id: '#/properties/sofactoappAmountVatC',
              $ref: '#/definitions/currency',
              title: 'sofactoapp__Amount_VAT__c',
              description: 'Total Amount incl. VAT',
            },
            supportMonthlyPriceC: {
              $id: '#/properties/supportMonthlyPriceC',
              $ref: '#/definitions/currency',
              title: 'Support_Monthly_Price__c',
              description: 'Support Monthly Price',
            },
            monthlyPriceQuantityC: {
              $id: '#/properties/monthlyPriceQuantityC',
              $ref: '#/definitions/currency',
              title: 'Monthly_Price_Quantity__c',
              description: 'Net Monthly Price',
            },
            sofactoTempStartDateC: {
              $id: '#/properties/sofactoTempStartDateC',
              type: ['string', 'null'],
              title: 'Sofacto_Temp_Start_Date__c',
              format: 'date',
              default: null,
              description: 'Sofacto Temp Start Date',
            },
            numberOfDaysOfSupportC: {
              $id: '#/properties/numberOfDaysOfSupportC',
              type: ['number', 'null'],
              title: 'Number_of_Days_of_Support__c',
              default: null,
              description: 'Number of Days of Support',
            },
            opportunityProductMrrC: {
              $id: '#/properties/opportunityProductMrrC',
              $ref: '#/definitions/currency',
              title: 'Opportunity_Product_MRR__c',
              description: 'Opportunity Product MRR',
            },
            magentrixOneProductNameMgtrxC: {
              $id: '#/properties/magentrixOneProductNameMgtrxC',
              type: ['string', 'null'],
              title: 'MagentrixOne__ProductName_mgtrx__c',
              default: null,
              description: 'Product Name',
            },
          },
          definitions: {
            currency: {
              type: 'object',
              properties: {
                value: { type: ['number', 'null'], default: null },
                symbol: {
                  enum: [
                    'AED',
                    'AFN',
                    'ALL',
                    'AMD',
                    'ANG',
                    'AOA',
                    'ARS',
                    'AUD',
                    'AWG',
                    'AZN',
                    'BAM',
                    'BBD',
                    'BDT',
                    'BGN',
                    'BHD',
                    'BIF',
                    'BMD',
                    'BND',
                    'BOB',
                    'BRL',
                    'BSD',
                    'BTC',
                    'BTN',
                    'BWP',
                    'BYN',
                    'BYR',
                    'BZD',
                    'CAD',
                    'CDF',
                    'CHF',
                    'CLF',
                    'CLP',
                    'CNY',
                    'COP',
                    'CRC',
                    'CUC',
                    'CUP',
                    'CVE',
                    'CZK',
                    'DJF',
                    'DKK',
                    'DOP',
                    'DZD',
                    'EGP',
                    'ERN',
                    'ETB',
                    'EUR',
                    'FJD',
                    'FKP',
                    'GBP',
                    'GEL',
                    'GGP',
                    'GHS',
                    'GIP',
                    'GMD',
                    'GNF',
                    'GTQ',
                    'GYD',
                    'HKD',
                    'HNL',
                    'HRK',
                    'HTG',
                    'HUF',
                    'IDR',
                    'ILS',
                    'IMP',
                    'INR',
                    'IQD',
                    'IRR',
                    'ISK',
                    'JEP',
                    'JMD',
                    'JOD',
                    'JPY',
                    'KES',
                    'KGS',
                    'KHR',
                    'KMF',
                    'KPW',
                    'KRW',
                    'KWD',
                    'KYD',
                    'KZT',
                    'LAK',
                    'LBP',
                    'LKR',
                    'LRD',
                    'LSL',
                    'LTL',
                    'LVL',
                    'LYD',
                    'MAD',
                    'MDL',
                    'MGA',
                    'MKD',
                    'MMK',
                    'MNT',
                    'MOP',
                    'MRO',
                    'MUR',
                    'MVR',
                    'MWK',
                    'MXN',
                    'MYR',
                    'MZN',
                    'NAD',
                    'NGN',
                    'NIO',
                    'NOK',
                    'NPR',
                    'NZD',
                    'OMR',
                    'PAB',
                    'PEN',
                    'PGK',
                    'PHP',
                    'PKR',
                    'PLN',
                    'PYG',
                    'QAR',
                    'RON',
                    'RSD',
                    'RUB',
                    'RWF',
                    'SAR',
                    'SBD',
                    'SCR',
                    'SDG',
                    'SEK',
                    'SGD',
                    'SHP',
                    'SLL',
                    'SOS',
                    'SRD',
                    'STD',
                    'SVC',
                    'SYP',
                    'SZL',
                    'THB',
                    'TJS',
                    'TMT',
                    'TND',
                    'TOP',
                    'TRY',
                    'TTD',
                    'TWD',
                    'TZS',
                    'UAH',
                    'UGX',
                    'USD',
                    'UYU',
                    'UZS',
                    'VEF',
                    'VND',
                    'VUV',
                    'WST',
                    'XAF',
                    'XAG',
                    'XAU',
                    'XCD',
                    'XDR',
                    'XOF',
                    'XPF',
                    'YER',
                    'ZAR',
                    'ZMK',
                    'ZMW',
                    'ZWL',
                  ],
                  type: 'string',
                  default: 'EUR',
                },
              },
            },
          },
          description: '',
          additionalProperties: false,
        },
        createdAt: '2020-09-08T08:15:12.098Z',
        updatedAt: '2020-12-13T23:00:03.945Z',
        externalIds: ['id'],
        machineName: 'opportunityLineItem',
        preferences: { duplicates: 'overwrite' },
      },
    },
    relationships: {
      previousOpportunity: {
        name: 'previousOpportunity',
        type: 'object',
        toDefinition: { machineName: 'opportunity' },
        fromDefinition: { machineName: 'opportunity' },
        toDefinitionField: 'id',
        fromDefinitionField: 'previousOpportunityIdC',
        id: 'c0d4e6dd-30cd-4563-a33b-dca3012de2be',
      },
      opportunityLineItems: {
        name: 'opportunityLineItems',
        type: 'array',
        toDefinition: { machineName: 'opportunity' },
        fromDefinition: { machineName: 'opportunity' },
        toDefinitionField: 'opportunityId',
        fromDefinitionField: 'id',
        id: 'c0d4e6dd-30cd-4563-a33b-dca3012de2be',
      },
    },
  },
  planAssignment: {
    id: '88a20764-0680-4093-9d47-f8786954d161',
    user: {
      id: 'c5fd0a81-9691-40ea-a1ed-29322cf8ca0a',
      email: 'pouet.plop@yolo.com',
      role: UserRole.EMPLOYEE,
      currency: 'EUR',
      language: 'en',
      lastName: 'Plop',
      settings: null,
      firstName: 'Pouet',
      pictureURL: null,
    },
    effectiveAsOf: 1_593_554_400,
    effectiveUntil: 1_609_369_200,
  },
  computedObjects: [
    {
      id: '7cb39c79482fa2f2a7d14560d2bbf250c530997c',
      type: 'VARIABLE',
      value: 200_000,
      format: 'currency',
      parentIds: [
        '1c34a805-c3f3-4963-b0ca-02276b234930',
        '78be3692a3678c5fd542764f30821d8783c310e0',
        '12fb0bfd7d6287acdf3104805a4fa01974ca6fe7',
        'c6b1fef8a83c02cfb43124fec4e602d8c7d301f2',
        'a2f994453f621cf6c6f06ee65fb165028da95483',
        '0dcff4693e797dbeae770612d39dc8bc2e953238',
      ],
      variableType: 'user',
      variableMachineName: 'target',
    },
    {
      id: '1bc7a3b97b69e40877d4883219810e01d01bb9cf',
      type: 'VARIABLE',
      value: 0.6,
      format: 'percent',
      parentIds: [
        '1c34a805-c3f3-4963-b0ca-02276b234930',
        '78be3692a3678c5fd542764f30821d8783c310e0',
        '12fb0bfd7d6287acdf3104805a4fa01974ca6fe7',
        'c6b1fef8a83c02cfb43124fec4e602d8c7d301f2',
      ],
      variableType: 'statement',
      variableMachineName: 'newAeTargetReach',
    },
    {
      id: '2271d649374603c5a93eca3d2c8e0e7affd64c64',
      type: 'VARIABLE',
      value: [
        [null, 0, 0],
        [0, 0.05, 0],
        [0.05, 0.1, 0.03],
        [0.1, 0.15, 0.07],
        [0.15, 0.2, 0.1],
        [0.2, 0.25, 0.13],
        [0.25, 0.3, 0.17],
        [0.3, 0.35, 0.2],
        [0.35, 0.4, 0.23],
        [0.4, 0.45, 0.27],
        [0.45, 0.5, 0.3],
        [0.5, 0.55, 0.33],
        [0.55, 0.6, 0.4],
        [0.6, 0.65, 0.47],
        [0.65, 0.7, 0.53],
        [0.7, 0.75, 0.6],
        [0.75, 0.8, 0.67],
        [0.8, 0.85, 0.73],
        [0.85, 0.9, 0.8],
        [0.9, 0.95, 0.87],
        [0.95, 1, 0.93],
        [1, 1.05, 1],
        [1.05, 1.1, 1.08],
        [1.1, 1.15, 1.15],
        [1.15, 1.2, 1.23],
        [1.2, 1.25, 1.3],
        [1.25, 1.3, 1.38],
        [1.3, 1.35, 1.45],
        [1.35, 1.4, 1.53],
        [1.4, 1.45, 1.6],
        [1.45, 1.5, 1.68],
        [1.5, 1.55, 1.75],
        [1.55, 1.6, 1.83],
        [1.6, 1.65, 1.9],
        [1.65, 1.7, 1.98],
        [1.7, 1.75, 2.05],
        [1.75, 1.8, 2.13],
        [1.8, 1.85, 2.2],
        [1.85, 1.9, 2.28],
        [1.9, 1.95, 2.35],
        [1.95, 2, 2.43],
        [2, null, 2.5],
      ],
      format: 'table',
      parentIds: [
        '1c34a805-c3f3-4963-b0ca-02276b234930',
        '78be3692a3678c5fd542764f30821d8783c310e0',
        '12fb0bfd7d6287acdf3104805a4fa01974ca6fe7',
        'c6b1fef8a83c02cfb43124fec4e602d8c7d301f2',
      ],
      variableType: 'statement',
      variableMachineName: 'newSalesCurve',
    },
    {
      id: '23683567270df961bc9df7bb477d121d722d64c9',
      type: 'VARIABLE',
      value: 0.47,
      format: 'percent',
      parentIds: ['1c34a805-c3f3-4963-b0ca-02276b234930', '78be3692a3678c5fd542764f30821d8783c310e0'],
      variableType: 'statement',
      variableMachineName: 'newAeAchievement',
    },
    {
      id: '6a90bbce66d521619ac3e11a1491697be99af20a',
      type: 'VARIABLE',
      value: 15_000,
      format: 'currency',
      parentIds: ['1c34a805-c3f3-4963-b0ca-02276b234930', '78be3692a3678c5fd542764f30821d8783c310e0'],
      variableType: 'user',
      variableMachineName: 'bonus',
    },
    {
      id: '47f53b68d09394857d211e67a60acee758beb10b',
      type: 'VARIABLE',
      value: 7050,
      format: 'currency',
      parentIds: ['1c34a805-c3f3-4963-b0ca-02276b234930'],
      variableType: 'statement',
      variableMachineName: 'newAeFinalBonusQuarter',
    },
    {
      type: 'RULE',
      value: 7050,
      ruleMachineName: 'newAeCommission',
    },
  ],
} as unknown as ComputedStatement;

export const mockStatementWithDatasetRelation = {
  definitions: {
    plan: {
      id: 'a52ac536-eb8a-43e0-8473-0512b5beeb18',
      name: 'ACCOUNT EXECUTIVE 2020-2021',
      rules: [
        {
          id: '1c34a805-c3f3-4963-b0ca-02276b234930',
          name: 'New AE Commission',
          index: 0,
          kpisToDisplay: [
            {
              id: '64106d44-83ec-4678-80db-72c40a421c24',
              displayStatus: 'ON_DISPLAY',
            },
            {
              id: '61a6a183-8657-4185-b364-701b8c49948c',
              displayStatus: 'ON_DISPLAY',
            },
            { id: 'aa04e6ae-8206-477a-af49-309b77cf0c1c', displayStatus: 'ON_DISPLAY' },
          ],
          fieldsToDisplay: [
            { name: 'id', displayStatus: 'AVAILABLE' },
            {
              name: 'name',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'closeDate', displayStatus: 'ON_DISPLAY' },
            {
              name: 'contractTermMonthsC',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'newLicence', displayStatus: 'ON_DISPLAY' },
            {
              name: 'newService',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'abrConverted', displayStatus: 'ON_DISPLAY' },
            {
              name: 'leadSource',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'serviceDeliveredByAgencyC', displayStatus: 'ON_DISPLAY' },
            {
              name: 'newMultiyearBonus',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'outboundDealBonus', displayStatus: 'ON_DISPLAY' },
            {
              name: 'agencyTransferBonus',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'licenceAccelerator', displayStatus: 'ON_DISPLAY' },
            {
              name: 'serviceDecelerator',
              displayStatus: 'ON_DISPLAY',
            },
            { name: 'newAeFinalAbr', displayStatus: 'ON_DISPLAY' },
          ],
          filterToDisplayId: '38151bc7-a42f-4190-99f6-0bf672fe6c17',
          planRuleAssignmentId: '223721bd-e7d9-4d33-9f46-7ef06e7cf6f6',
        },
      ],
      highlightedKpis: [
        {
          identifier: 'secondary',
          variableId: '64106d44-83ec-4678-80db-72c40a421c24',
          maximumVariableId: '61a6a183-8657-4185-b364-701b8c49948c',
          minimumVariableId: null,
        },
        {
          identifier: 'primary',
          variableId: '0a1eb2e3-ea14-4ff1-b0d5-e9641ed143dc',
          maximumVariableId: '60efc1f3-9088-4a07-9b7a-7e37da241d0f',
          minimumVariableId: null,
        },
      ],
    },
    filters: {
      closedInQuarter: {
        id: '38151bc7-a42f-4190-99f6-0bf672fe6c17',
        name: 'Closed In Quarter',
        machineName: 'closedInQuarter',
        customObjectDefinitionMachineName: 'opportunity',
        condition: 'opportunity.closeDate > 1000 AND opportunity.ownerId = "swag" AND opportunity.isWon = true',
      },
    },
    variables: {
      bonus: {
        id: '60efc1f3-9088-4a07-9b7a-7e37da241d0f',
        name: 'Bonus',
        type: 'user',
        format: 'currency',
        formula: '',
        machineName: 'bonus',
      },
      target: {
        id: '61a6a183-8657-4185-b364-701b8c49948c',
        name: 'Target',
        type: 'user',
        format: 'currency',
        formula: '',
        machineName: 'target',
      },
      licenceRn: {
        id: 'e5567c5e-1b07-49f9-a1c3-62c95f51212a',
        name: 'Licence RN',
        type: 'object',
        format: 'currency',
        formula: 'convertCurrency(opportunity.licenceRnNc, opportunity.currencyIsoCode, user.currency)',
        machineName: 'licenceRn',
      },
      serviceNb: {
        id: '0245257e-5f1d-4f00-87b9-9d44d7fd957b',
        name: 'Service NB',
        type: 'object',
        format: 'currency',
        formula: 'convertCurrency(opportunity.serviceNbNc, opportunity.currencyIsoCode, user.currency)',
        machineName: 'serviceNb',
      },
      newLicence: {
        id: 'e5846e5b-3811-4b89-b6f2-0081d311a211',
        name: 'New Licence',
        type: 'object',
        format: 'currency',
        formula: 'opportunity.licenceRn - opportunity.previousLicence',
        machineName: 'newLicence',
      },
      licenceRnNc: {
        id: '8dfa453f-a12b-41b2-86d9-2536f184e61d',
        name: 'Licence RN NC',
        type: 'object',
        format: 'currency',
        formula:
          'SUM(opportunity.opportunityLineItems, IF(opportunityLineItem.opportunityStageC = "6 - Closed Won", IF(CONTAINS(statement.newProductLicenceMapping, opportunityLineItem.magentrixOneProductNameMgtrxC) = true, opportunityLineItem.totalPrice / opportunityLineItem.contractTermMonthsC * 12, 0), 0))',
        machineName: 'licenceRnNc',
      },
      serviceNbNc: {
        id: '19cc9dcb-0581-4d0b-af66-714f3c9d1ccc',
        name: 'Service NB NC',
        type: 'object',
        format: 'currency',
        formula:
          'SUM(opportunity.opportunityLineItems, IF(opportunityLineItem.opportunityStageC = "6 - Closed Won", IF(CONTAINS(statement.newProductServiceMapping, opportunityLineItem.magentrixOneProductNameMgtrxC) = true, IF(opportunityLineItem.contractTermMonthsC > 12, opportunityLineItem.totalPrice / opportunityLineItem.contractTermMonthsC * 12, opportunityLineItem.totalPrice), 0), 0))',
        machineName: 'serviceNbNc',
      },
      abrConverted: {
        id: '3e058443-7ba8-49fe-9758-f9438427dd9b',
        name: 'ABR Converted',
        type: 'object',
        format: 'currency',
        formula: 'convertCurrency(opportunity.jppAmountC, opportunity.currencyIsoCode, user.currency)',
        machineName: 'abrConverted',
      },
      newAeFinalAbr: {
        id: 'c4bd50bb-7e4d-4aa9-84b1-4d5d8560c171',
        name: 'New AE Final ABR',
        type: 'object',
        format: 'currency',
        formula:
          'opportunity.abrConverted + opportunity.newMultiyearBonus + opportunity.outboundDealBonus + opportunity.agencyTransferBonus + opportunity.licenceAccelerator + opportunity.serviceDecelerator',
        machineName: 'newAeFinalAbr',
      },
      newSalesCurve: {
        id: '9b137b43-296f-4807-8c95-7768a520cda0',
        name: 'New Sales Curve',
        type: 'statement',
        format: 'table',
        formula:
          '[[-Infinity, 0, 0], [0, 0.05, 0], [0.05, 0.1, 0.03], [0.1, 0.15, 0.07], [0.15, 0.2, 0.1], [0.2, 0.25, 0.13], [0.25, 0.3, 0.17], [0.3, 0.35, 0.2], [0.35, 0.4, 0.23], [0.4, 0.45, 0.27], [0.45, 0.5, 0.3], [0.5, 0.55, 0.33], [0.55, 0.6, 0.4], [0.6, 0.65, 0.47], [0.65, 0.7, 0.53], [0.7, 0.75, 0.6], [0.75, 0.8, 0.67], [0.8, 0.85, 0.73], [0.85, 0.9, 0.8], [0.9, 0.95, 0.87], [0.95, 1, 0.93], [1, 1.05, 1], [1.05, 1.1, 1.08], [1.1, 1.15, 1.15], [1.15, 1.2, 1.23], [1.2, 1.25, 1.3], [1.25, 1.3, 1.38], [1.3, 1.35, 1.45], [1.35, 1.4, 1.53], [1.4, 1.45, 1.6], [1.45, 1.5, 1.68], [1.5, 1.55, 1.75], [1.55, 1.6, 1.83], [1.6, 1.65, 1.9], [1.65, 1.7, 1.98], [1.7, 1.75, 2.05], [1.75, 1.8, 2.13], [1.8, 1.85, 2.2], [1.85, 1.9, 2.28], [1.9, 1.95, 2.35], [1.95, 2, 2.43], [2, Infinity, 2.5]]',
        machineName: 'newSalesCurve',
      },
      previousLicence: {
        id: 'b3409e9e-8eb7-4fc3-93c2-9cdbd039b491',
        name: 'Previous Licence',
        type: 'object',
        format: 'currency',
        formula: 'IF(opportunity.previousOpportunity, opportunity.previousOpportunity.licenceRn, 0)',
        machineName: 'previousLicence',
      },
      newAeAchievement: {
        id: 'aa04e6ae-8206-477a-af49-309b77cf0c1c',
        name: 'New AE Achievement',
        type: 'statement',
        format: 'percent',
        formula: 'LINEAR(statement.newAeTargetReach, statement.newSalesCurve)',
        machineName: 'newAeAchievement',
      },
      newAeTargetReach: {
        id: '0c54518c-6c07-43e3-808a-bf94e9f45bfd',
        name: 'New AE Target Reach',
        type: 'statement',
        format: 'percent',
        formula: 'floor(statement.newAeFinalAbrBonusBase / user.target * 100) / 100',
        machineName: 'newAeTargetReach',
      },
      newMultiyearBonus: {
        id: '3848cf96-fe46-48fb-acd0-7338eb55ed95',
        name: 'New Multiyear Bonus',
        type: 'object',
        format: 'currency',
        formula:
          'IF(opportunity.contractTermMonthsC = 24, IF(opportunity.abrConverted > 60000, 0.25 * opportunity.abrConverted, IF(opportunity.abrConverted > 20000, 0.2 * opportunity.abrConverted, 0)), 0) + IF(opportunity.contractTermMonthsC = 36, IF(opportunity.abrConverted > 60000, 0.35 * opportunity.abrConverted, IF(opportunity.abrConverted > 20000, 0.3 * opportunity.abrConverted, 0)), 0)',
        machineName: 'newMultiyearBonus',
      },
      outboundDealBonus: {
        id: 'f2b51ac0-add3-41dc-893c-e541fdfe37a4',
        name: 'Outbound Deal Bonus',
        type: 'object',
        format: 'currency',
        formula: 'IF(opportunity.leadSource = "Outbound", opportunity.newLicence * 0.2, 0)',
        machineName: 'outboundDealBonus',
      },
      licenceAccelerator: {
        id: '59255fc1-283e-4922-8f4b-d9d9af410e93',
        name: 'Licence Accelerator',
        type: 'object',
        format: 'currency',
        formula: 'opportunity.newLicence * 0.3',
        machineName: 'licenceAccelerator',
      },
      serviceDecelerator: {
        id: '29d2f572-39c3-4e0c-a077-fb858752be38',
        name: 'Service Decelerator',
        type: 'object',
        format: 'currency',
        formula: '-opportunity.serviceNb * 0.9',
        machineName: 'serviceDecelerator',
      },
      agencyTransferBonus: {
        id: '7f9a4537-4478-4774-8231-d2200651a87a',
        name: 'Agency Transfer Bonus',
        type: 'object',
        format: 'currency',
        formula: 'IF(opportunity.serviceDeliveredByAgencyC = true, opportunity.newLicence * 0.1, 0)',
        machineName: 'agencyTransferBonus',
      },
      newAeFinalAbrBonusBase: {
        id: '64106d44-83ec-4678-80db-72c40a421c24',
        name: 'New AE Final ABR Bonus Base',
        type: 'statement',
        format: 'currency',
        formula: 'SUM(filter.closedInQuarter, opportunity.newAeFinalAbr)',
        machineName: 'newAeFinalAbrBonusBase',
      },
      newAeFinalBonusQuarter: {
        id: '0a1eb2e3-ea14-4ff1-b0d5-e9641ed143dc',
        name: 'New AE Final Bonus Quarter',
        type: 'statement',
        format: 'currency',
        formula: 'statement.newAeAchievement * user.bonus',
        machineName: 'newAeFinalBonusQuarter',
      },
      newProductLicenceMapping: {
        id: '9fe3a232-26a3-4c03-aef3-3f663ca9c182',
        name: 'New Product Licence Mapping',
        type: 'statement',
        format: 'table',
        formula:
          '["Licence - Enterprise Web", "License - Elite", "License - Essentials", "License - Growth", "License AB Tasty - Elite", "License AB Tasty - Essentials", "License AB Tasty - Growth", "License Flagship - Elite", "License Flagship - Essentials", "License Flagship - Growth", "Module - Data Activation", "Module - Dynamic Widgets (Revenue Share Contract)", "Module - Server Side", "Module - User Insights", "Module - UX Analytics", "Option - DMP Connector", "Option - NPS", "Option - Session Recording"]',
        machineName: 'newProductLicenceMapping',
      },
      newProductServiceMapping: {
        id: 'eeae7566-a688-4bbc-b5ce-c117864959c2',
        name: 'New Product Service Mapping',
        type: 'statement',
        format: 'table',
        formula:
          '["Ongoing Training", "Ongoing Training(On Site)", "Ongoing Training(Open)", "Ongoing Training(Webinar)", "Professional Services - 4 regular campaigns(One time)", "Professional Services - Analysis Pack(5 Campaigns)", "Professional Services - Migration booster(10 regular campaigns)", "Professional Services - Recurring implementation(2 regular campaigns)", "Professional Services - Recurring implementation(4 regular campaigns)", "Professional Services - Urgent implementation(2 regular campaigns)", "Support - Days (8h) of support/month", "Support - Pay as you go", "Workshop / (CRO Academy(2 workshops))", "Workshop / (CRO Academy(4 workshops))", "Workshop / (CRO Academy(6 workshops))"]',
        machineName: 'newProductServiceMapping',
      },
    },
    customObjects: {
      opportunity: {
        id: '860ec547-66dc-4837-b303-d214bebe60a2',
        name: 'Opportunity',
        type: CustomObjectDefinitionType.SALESFORCE,
        properties: {
          id: {
            name: 'Opportunity ID',
            machineName: 'id',
            format: FormatsEnum.text,
            isRequired: true,
          },
          name: {
            name: 'Name',
            machineName: 'name',
            format: FormatsEnum.text,
            isRequired: false,
          },
          type: {
            name: 'Opportunity Type',
            machineName: 'type',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isWon: {
            name: 'Won',
            machineName: 'isWon',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          amount: {
            name: 'Amount',
            machineName: 'amount',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          officeC: {
            name: 'Opportunity Office',
            machineName: 'officeC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          ownerId: {
            name: 'Owner ID',
            machineName: 'ownerId',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isClosed: {
            name: 'Closed',
            machineName: 'isClosed',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          paymentC: {
            name: 'Invoicing Frequency',
            machineName: 'paymentC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          accountId: {
            name: 'Account ID',
            machineName: 'accountId',
            format: FormatsEnum.text,
            isRequired: false,
          },
          closeDate: {
            name: 'Close Date',
            machineName: 'closeDate',
            format: FormatsEnum.date,
            isRequired: false,
          },
          stageName: {
            name: 'Stage',
            machineName: 'stageName',
            format: FormatsEnum.text,
            isRequired: false,
          },
          expansionC: {
            name: 'Expansion',
            machineName: 'expansionC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          jppAmountC: {
            name: 'ABR',
            machineName: 'jppAmountC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          leadSource: {
            name: 'Lead Source',
            machineName: 'leadSource',
            format: FormatsEnum.text,
            isRequired: false,
          },
          createdDate: {
            name: 'Created Date',
            machineName: 'createdDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          arrActualsFC: {
            name: 'ARR Actuals (f)',
            machineName: 'arrActualsFC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          churnReasonC: {
            name: 'Churn Reason',
            machineName: 'churnReasonC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          licenceMrr2C: {
            name: 'Licence MRR',
            machineName: 'licenceMrr2C',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          supportMrr2C: {
            name: 'Support MRR',
            machineName: 'supportMrr2C',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          currencyIsoCode: {
            name: 'Opportunity Currency',
            machineName: 'currencyIsoCode',
            format: FormatsEnum.text,
            isRequired: false,
          },
          lastModifiedDate: {
            name: 'Last Modified Date',
            machineName: 'lastModifiedDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          businessChurnArrC: {
            name: 'Business Churn ARR',
            machineName: 'businessChurnArrC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          previousOppsNameC: {
            name: 'Previous Opportunity Name',
            machineName: 'previousOppsNameC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          contractTermMonthsC: {
            name: 'Contract Term (months)',
            machineName: 'contractTermMonthsC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          annualContractValueC: {
            name: 'Annual Contract Value',
            machineName: 'annualContractValueC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          previousOpportunityIdC: {
            name: 'Previous Opportunity ID',
            machineName: 'previousOpportunityIdC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          sofactoappPeriodiciteC: {
            name: 'Frequency',
            machineName: 'sofactoappPeriodiciteC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          annualRecurringRevenueC: {
            name: 'Annual Recurring Revenue',
            machineName: 'annualRecurringRevenueC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          previousContractEndDateC: {
            name: 'Previous Contract End Date',
            machineName: 'previousContractEndDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          serviceDeliveredByAgencyC: {
            name: 'Service Delivered By Agency',
            machineName: 'serviceDeliveredByAgencyC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          renewedSubscriptionsAmountC: {
            name: 'Renewed Subscriptions Amount',
            machineName: 'renewedSubscriptionsAmountC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
        },
        schema: {
          $id: 'http://amalia.io/opportunity.schema.json',
          type: 'object',
          title: 'Opportunity',
          $schema: 'http://json-schema.org/draft-07/schema#',
          required: ['id'],
          properties: {
            id: {
              $id: '#/properties/id',
              type: 'string',
              title: 'Id',
              description: 'Opportunity ID',
            },
            name: {
              $id: '#/properties/name',
              type: ['string', 'null'],
              title: 'Name',
              default: null,
              description: 'Name',
            },
            type: {
              $id: '#/properties/type',
              type: ['string', 'null'],
              title: 'Type',
              default: null,
              description: 'Opportunity Type',
            },
            isWon: {
              $id: '#/properties/isWon',
              type: ['boolean', 'null'],
              title: 'IsWon',
              default: null,
              description: 'Won',
            },
            amount: {
              $id: '#/properties/amount',
              $ref: '#/definitions/currency',
              title: 'Amount',
              description: 'Amount',
            },
            officeC: {
              $id: '#/properties/officeC',
              type: ['string', 'null'],
              title: 'Office__c',
              default: null,
              description: 'Opportunity Office',
            },
            ownerId: {
              $id: '#/properties/ownerId',
              type: ['string', 'null'],
              title: 'OwnerId',
              default: null,
              description: 'Owner ID',
            },
            isClosed: {
              $id: '#/properties/isClosed',
              type: ['boolean', 'null'],
              title: 'IsClosed',
              default: null,
              description: 'Closed',
            },
            paymentC: {
              $id: '#/properties/paymentC',
              type: ['string', 'null'],
              title: 'Payment__c',
              default: null,
              description: 'Invoicing Frequency',
            },
            accountId: {
              $id: '#/properties/accountId',
              type: ['string', 'null'],
              title: 'AccountId',
              default: null,
              description: 'Account ID',
            },
            closeDate: {
              $id: '#/properties/closeDate',
              type: ['string', 'null'],
              title: 'CloseDate',
              format: 'date',
              default: null,
              description: 'Close Date',
            },
            stageName: {
              $id: '#/properties/stageName',
              type: ['string', 'null'],
              title: 'StageName',
              default: null,
              description: 'Stage',
            },
            expansionC: {
              $id: '#/properties/expansionC',
              type: ['boolean', 'null'],
              title: 'Expansion__c',
              default: null,
              description: 'Expansion',
            },
            jppAmountC: {
              $id: '#/properties/jppAmountC',
              $ref: '#/definitions/currency',
              title: 'JPP_Amount__c',
              description: 'ABR',
            },
            leadSource: {
              $id: '#/properties/leadSource',
              type: ['string', 'null'],
              title: 'LeadSource',
              default: null,
              description: 'Lead Source',
            },
            createdDate: {
              $id: '#/properties/createdDate',
              type: ['string', 'null'],
              title: 'CreatedDate',
              format: 'date-time',
              default: null,
              description: 'Created Date',
            },
            arrActualsFC: {
              $id: '#/properties/arrActualsFC',
              $ref: '#/definitions/currency',
              title: 'ARRActuals_f__c',
              description: 'ARR Actuals (f)',
            },
            churnReasonC: {
              $id: '#/properties/churnReasonC',
              type: ['string', 'null'],
              title: 'Churn_reason__c',
              default: null,
              description: 'Churn Reason',
            },
            licenceMrr2C: {
              $id: '#/properties/licenceMrr2C',
              $ref: '#/definitions/currency',
              title: 'Licence_MRR2__c',
              description: 'Licence MRR',
            },
            supportMrr2C: {
              $id: '#/properties/supportMrr2C',
              $ref: '#/definitions/currency',
              title: 'Support_MRR2__c',
              description: 'Support MRR',
            },
            currencyIsoCode: {
              $id: '#/properties/currencyIsoCode',
              type: ['string', 'null'],
              title: 'CurrencyIsoCode',
              default: null,
              description: 'Opportunity Currency',
            },
            lastModifiedDate: {
              $id: '#/properties/lastModifiedDate',
              type: ['string', 'null'],
              title: 'LastModifiedDate',
              format: 'date-time',
              default: null,
              description: 'Last Modified Date',
            },
            businessChurnArrC: {
              $id: '#/properties/businessChurnArrC',
              $ref: '#/definitions/currency',
              title: 'Business_Churn_ARR__c',
              description: 'Business Churn ARR',
            },
            previousOppsNameC: {
              $id: '#/properties/previousOppsNameC',
              type: ['string', 'null'],
              title: 'Previous_opps_name__c',
              default: null,
              description: 'Previous Opportunity Name',
            },
            contractTermMonthsC: {
              $id: '#/properties/contractTermMonthsC',
              type: ['number', 'null'],
              title: 'Contract_Term_months__c',
              default: null,
              description: 'Contract Term (months)',
            },
            annualContractValueC: {
              $id: '#/properties/annualContractValueC',
              $ref: '#/definitions/currency',
              title: 'Annual_Contract_Value__c',
              description: 'Annual Contract Value',
            },
            previousOpportunityIdC: {
              $id: '#/properties/previousOpportunityIdC',
              type: ['string', 'null'],
              title: 'Previous_Opportunity_ID__c',
              default: null,
              description: 'Previous Opportunity ID',
            },
            sofactoappPeriodiciteC: {
              $id: '#/properties/sofactoappPeriodiciteC',
              type: ['string', 'null'],
              title: 'sofactoapp__Periodicite__c',
              default: null,
              description: 'Frequency',
            },
            annualRecurringRevenueC: {
              $id: '#/properties/annualRecurringRevenueC',
              $ref: '#/definitions/currency',
              title: 'Annual_Recurring_Revenue__c',
              description: 'Annual Recurring Revenue',
            },
            previousContractEndDateC: {
              $id: '#/properties/previousContractEndDateC',
              type: ['string', 'null'],
              title: 'Previous_Contract_End_Date__c',
              format: 'date',
              default: null,
              description: 'Previous Contract End Date',
            },
            serviceDeliveredByAgencyC: {
              $id: '#/properties/serviceDeliveredByAgencyC',
              type: ['boolean', 'null'],
              title: 'Service_Delivered_By_Agency__c',
              default: null,
              description: 'Service Delivered By Agency',
            },
            renewedSubscriptionsAmountC: {
              $id: '#/properties/renewedSubscriptionsAmountC',
              $ref: '#/definitions/currency',
              title: 'Renewed_Subscriptions_Amount__c',
              description: 'Renewed Subscriptions Amount',
            },
          },
          definitions: {
            currency: {
              type: 'object',
              properties: {
                value: { type: ['number', 'null'], default: null },
                symbol: {
                  enum: [
                    'AED',
                    'AFN',
                    'ALL',
                    'AMD',
                    'ANG',
                    'AOA',
                    'ARS',
                    'AUD',
                    'AWG',
                    'AZN',
                    'BAM',
                    'BBD',
                    'BDT',
                    'BGN',
                    'BHD',
                    'BIF',
                    'BMD',
                    'BND',
                    'BOB',
                    'BRL',
                    'BSD',
                    'BTC',
                    'BTN',
                    'BWP',
                    'BYN',
                    'BYR',
                    'BZD',
                    'CAD',
                    'CDF',
                    'CHF',
                    'CLF',
                    'CLP',
                    'CNY',
                    'COP',
                    'CRC',
                    'CUC',
                    'CUP',
                    'CVE',
                    'CZK',
                    'DJF',
                    'DKK',
                    'DOP',
                    'DZD',
                    'EGP',
                    'ERN',
                    'ETB',
                    'EUR',
                    'FJD',
                    'FKP',
                    'GBP',
                    'GEL',
                    'GGP',
                    'GHS',
                    'GIP',
                    'GMD',
                    'GNF',
                    'GTQ',
                    'GYD',
                    'HKD',
                    'HNL',
                    'HRK',
                    'HTG',
                    'HUF',
                    'IDR',
                    'ILS',
                    'IMP',
                    'INR',
                    'IQD',
                    'IRR',
                    'ISK',
                    'JEP',
                    'JMD',
                    'JOD',
                    'JPY',
                    'KES',
                    'KGS',
                    'KHR',
                    'KMF',
                    'KPW',
                    'KRW',
                    'KWD',
                    'KYD',
                    'KZT',
                    'LAK',
                    'LBP',
                    'LKR',
                    'LRD',
                    'LSL',
                    'LTL',
                    'LVL',
                    'LYD',
                    'MAD',
                    'MDL',
                    'MGA',
                    'MKD',
                    'MMK',
                    'MNT',
                    'MOP',
                    'MRO',
                    'MUR',
                    'MVR',
                    'MWK',
                    'MXN',
                    'MYR',
                    'MZN',
                    'NAD',
                    'NGN',
                    'NIO',
                    'NOK',
                    'NPR',
                    'NZD',
                    'OMR',
                    'PAB',
                    'PEN',
                    'PGK',
                    'PHP',
                    'PKR',
                    'PLN',
                    'PYG',
                    'QAR',
                    'RON',
                    'RSD',
                    'RUB',
                    'RWF',
                    'SAR',
                    'SBD',
                    'SCR',
                    'SDG',
                    'SEK',
                    'SGD',
                    'SHP',
                    'SLL',
                    'SOS',
                    'SRD',
                    'STD',
                    'SVC',
                    'SYP',
                    'SZL',
                    'THB',
                    'TJS',
                    'TMT',
                    'TND',
                    'TOP',
                    'TRY',
                    'TTD',
                    'TWD',
                    'TZS',
                    'UAH',
                    'UGX',
                    'USD',
                    'UYU',
                    'UZS',
                    'VEF',
                    'VND',
                    'VUV',
                    'WST',
                    'XAF',
                    'XAG',
                    'XAU',
                    'XCD',
                    'XDR',
                    'XOF',
                    'XPF',
                    'YER',
                    'ZAR',
                    'ZMK',
                    'ZMW',
                    'ZWL',
                  ],
                  type: 'string',
                  default: 'EUR',
                },
              },
            },
          },
          description: '',
          additionalProperties: false,
        },
        createdAt: '2020-08-26T13:52:22.265Z',
        updatedAt: '2020-12-13T23:00:03.864Z',
        externalIds: ['id'],
        machineName: 'opportunity',
        preferences: { duplicates: 'overwrite' },
      },
      opportunityLineItem: {
        id: '469c3862-21d2-4005-b663-67c7e0e7b0cf',
        name: 'OpportunityLineItem',
        type: CustomObjectDefinitionType.SALESFORCE,
        properties: {
          id: {
            name: 'Line Item ID',
            machineName: 'id',
            format: FormatsEnum.text,
            isRequired: true,
          },
          name: {
            name: 'Opportunity Product Name',
            machineName: 'name',
            format: FormatsEnum.text,
            isRequired: false,
          },
          test: {
            name: 'Test',
            machineName: 'test',
            format: FormatsEnum.text,
            isRequired: false,
          },
          flagC: {
            name: 'Flag',
            machineName: 'flagC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          assetC: {
            name: 'Asset?',
            machineName: 'assetC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          discount: {
            name: 'Discount',
            machineName: 'discount',
            format: FormatsEnum.number,
            isRequired: false,
          },
          quantity: {
            name: 'Quantity',
            machineName: 'quantity',
            format: FormatsEnum.number,
            isRequired: false,
          },
          renewalC: {
            name: 'Renewal',
            machineName: 'renewalC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          supportC: {
            name: 'Support',
            machineName: 'supportC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          abFamilyC: {
            name: 'AB Family',
            machineName: 'abFamilyC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isDeleted: {
            name: 'Deleted',
            machineName: 'isDeleted',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          listPrice: {
            name: 'List Price',
            machineName: 'listPrice',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          sortOrder: {
            name: 'Sort Order',
            machineName: 'sortOrder',
            format: FormatsEnum.number,
            isRequired: false,
          },
          unitPrice: {
            name: 'Sales Price',
            machineName: 'unitPrice',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          isSupportC: {
            name: 'Is Support',
            machineName: 'isSupportC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          product2Id: {
            name: 'Product ID',
            machineName: 'product2Id',
            format: FormatsEnum.text,
            isRequired: false,
          },
          totalPrice: {
            name: 'Total Price',
            machineName: 'totalPrice',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          createdById: {
            name: 'Created By ID',
            machineName: 'createdById',
            format: FormatsEnum.text,
            isRequired: false,
          },
          createdDate: {
            name: 'Created Date',
            machineName: 'createdDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          description: {
            name: 'Line Description',
            machineName: 'description',
            format: FormatsEnum.text,
            isRequired: false,
          },
          productCode: {
            name: 'Product Code',
            machineName: 'productCode',
            format: FormatsEnum.text,
            isRequired: false,
          },
          serviceDate: {
            name: 'Date',
            machineName: 'serviceDate',
            format: FormatsEnum.date,
            isRequired: false,
          },
          opportunityC: {
            name: 'Opportunity',
            machineName: 'opportunityC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          productTermC: {
            name: 'Product Term',
            machineName: 'productTermC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          supportDaysC: {
            name: 'Support days',
            machineName: 'supportDaysC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          businessTypeC: {
            name: 'Sales Type',
            machineName: 'businessTypeC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          monthlyPriceC: {
            name: 'Monthly Price',
            machineName: 'monthlyPriceC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          opportunityId: {
            name: 'Opportunity ID',
            machineName: 'opportunityId',
            format: FormatsEnum.text,
            isRequired: false,
          },
          isLicenceOnlyC: {
            name: 'Is Licence Only',
            machineName: 'isLicenceOnlyC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          systemModstamp: {
            name: 'System Modstamp',
            machineName: 'systemModstamp',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          currencyIsoCode: {
            name: 'Currency ISO Code',
            machineName: 'currencyIsoCode',
            format: FormatsEnum.text,
            isRequired: false,
          },
          monthlySupportC: {
            name: 'Monthly Support',
            machineName: 'monthlySupportC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          contractEndDateC: {
            name: 'Contract End Date',
            machineName: 'contractEndDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          flagshipProductC: {
            name: 'Flagship Product',
            machineName: 'flagshipProductC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          lastModifiedById: {
            name: 'Last Modified By ID',
            machineName: 'lastModifiedById',
            format: FormatsEnum.text,
            isRequired: false,
          },
          lastModifiedDate: {
            name: 'Last Modified Date',
            machineName: 'lastModifiedDate',
            format: FormatsEnum['date-time'],
            isRequired: false,
          },
          renewalVarianceC: {
            name: 'Renewal Variance (%)',
            machineName: 'renewalVarianceC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          opportunityStageC: {
            name: 'Opportunity Stage',
            machineName: 'opportunityStageC',
            format: FormatsEnum.text,
            isRequired: false,
          },
          contractStartDateC: {
            name: 'Contract Start Date',
            machineName: 'contractStartDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          isModuleOrOptionsC: {
            name: 'Is Module or Options',
            machineName: 'isModuleOrOptionsC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          contractTermMonthsC: {
            name: 'Contract Term (months)',
            machineName: 'contractTermMonthsC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          expectedTotalPriceC: {
            name: 'Expected Total Price',
            machineName: 'expectedTotalPriceC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          ongoingOpportunityC: {
            name: 'Ongoing Opportunity',
            machineName: 'ongoingOpportunityC',
            format: FormatsEnum.boolean,
            isRequired: false,
          },
          sofactoappAmountVatC: {
            name: 'Total Amount incl. VAT',
            machineName: 'sofactoappAmountVatC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          supportMonthlyPriceC: {
            name: 'Support Monthly Price',
            machineName: 'supportMonthlyPriceC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          monthlyPriceQuantityC: {
            name: 'Net Monthly Price',
            machineName: 'monthlyPriceQuantityC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          sofactoTempStartDateC: {
            name: 'Sofacto Temp Start Date',
            machineName: 'sofactoTempStartDateC',
            format: FormatsEnum.date,
            isRequired: false,
          },
          numberOfDaysOfSupportC: {
            name: 'Number of Days of Support',
            machineName: 'numberOfDaysOfSupportC',
            format: FormatsEnum.number,
            isRequired: false,
          },
          opportunityProductMrrC: {
            name: 'Opportunity Product MRR',
            machineName: 'opportunityProductMrrC',
            format: FormatsEnum.currency,
            isRequired: true,
          },
          magentrixOneProductNameMgtrxC: {
            name: 'Product Name',
            machineName: 'magentrixOneProductNameMgtrxC',
            format: FormatsEnum.text,
            isRequired: false,
          },
        },
        schema: {
          $id: 'http://amalia.io/opportunityLineItem.schema.json',
          type: 'object',
          title: 'OpportunityLineItem',
          $schema: 'http://json-schema.org/draft-07/schema#',
          required: ['id'],
          properties: {
            id: {
              $id: '#/properties/id',
              type: 'string',
              title: 'Id',
              description: 'Line Item ID',
            },
            name: {
              $id: '#/properties/name',
              type: ['string', 'null'],
              title: 'Name',
              default: null,
              description: 'Opportunity Product Name',
            },
            flagC: {
              $id: '#/properties/flagC',
              type: ['string', 'null'],
              title: 'Flag__c',
              default: null,
              description: 'Flag',
            },
            assetC: {
              $id: '#/properties/assetC',
              type: ['boolean', 'null'],
              title: 'Asset__c',
              default: null,
              description: 'Asset?',
            },
            discount: {
              $id: '#/properties/discount',
              type: ['number', 'null'],
              title: 'Discount',
              default: null,
              description: 'Discount',
            },
            quantity: {
              $id: '#/properties/quantity',
              type: ['number', 'null'],
              title: 'Quantity',
              default: null,
              description: 'Quantity',
            },
            renewalC: {
              $id: '#/properties/renewalC',
              type: ['string', 'null'],
              title: 'Renewal__c',
              default: null,
              description: 'Renewal',
            },
            supportC: {
              $id: '#/properties/supportC',
              type: ['number', 'null'],
              title: 'Support__c',
              default: null,
              description: 'Support',
            },
            abFamilyC: {
              $id: '#/properties/abFamilyC',
              type: ['string', 'null'],
              title: 'AB_Family__c',
              default: null,
              description: 'AB Family',
            },
            isDeleted: {
              $id: '#/properties/isDeleted',
              type: ['boolean', 'null'],
              title: 'IsDeleted',
              default: null,
              description: 'Deleted',
            },
            listPrice: {
              $id: '#/properties/listPrice',
              $ref: '#/definitions/currency',
              title: 'ListPrice',
              description: 'List Price',
            },
            sortOrder: {
              $id: '#/properties/sortOrder',
              type: ['number', 'null'],
              title: 'SortOrder',
              default: null,
              description: 'Sort Order',
            },
            unitPrice: {
              $id: '#/properties/unitPrice',
              $ref: '#/definitions/currency',
              title: 'UnitPrice',
              description: 'Sales Price',
            },
            isSupportC: {
              $id: '#/properties/isSupportC',
              type: ['boolean', 'null'],
              title: 'Is_Support__c',
              default: null,
              description: 'Is Support',
            },
            product2Id: {
              $id: '#/properties/product2Id',
              type: ['string', 'null'],
              title: 'Product2Id',
              default: null,
              description: 'Product ID',
            },
            totalPrice: {
              $id: '#/properties/totalPrice',
              $ref: '#/definitions/currency',
              title: 'TotalPrice',
              description: 'Total Price',
            },
            createdById: {
              $id: '#/properties/createdById',
              type: ['string', 'null'],
              title: 'CreatedById',
              default: null,
              description: 'Created By ID',
            },
            createdDate: {
              $id: '#/properties/createdDate',
              type: ['string', 'null'],
              title: 'CreatedDate',
              format: 'date-time',
              default: null,
              description: 'Created Date',
            },
            description: {
              $id: '#/properties/description',
              type: ['string', 'null'],
              title: 'Description',
              default: null,
              description: 'Line Description',
            },
            productCode: {
              $id: '#/properties/productCode',
              type: ['string', 'null'],
              title: 'ProductCode',
              default: null,
              description: 'Product Code',
            },
            serviceDate: {
              $id: '#/properties/serviceDate',
              type: ['string', 'null'],
              title: 'ServiceDate',
              format: 'date',
              default: null,
              description: 'Date',
            },
            opportunityC: {
              $id: '#/properties/opportunityC',
              type: ['string', 'null'],
              title: 'Opportunity__c',
              default: null,
              description: 'Opportunity',
            },
            productTermC: {
              $id: '#/properties/productTermC',
              type: ['number', 'null'],
              title: 'Product_Term__c',
              default: null,
              description: 'Product Term',
            },
            supportDaysC: {
              $id: '#/properties/supportDaysC',
              type: ['number', 'null'],
              title: 'Support_days__c',
              default: null,
              description: 'Support days',
            },
            businessTypeC: {
              $id: '#/properties/businessTypeC',
              type: ['string', 'null'],
              title: 'Business_Type__c',
              default: null,
              description: 'Sales Type',
            },
            monthlyPriceC: {
              $id: '#/properties/monthlyPriceC',
              $ref: '#/definitions/currency',
              title: 'Monthly_Price__c',
              description: 'Monthly Price',
            },
            opportunityId: {
              $id: '#/properties/opportunityId',
              type: ['string', 'null'],
              title: 'OpportunityId',
              default: null,
              description: 'Opportunity ID',
            },
            isLicenceOnlyC: {
              $id: '#/properties/isLicenceOnlyC',
              type: ['boolean', 'null'],
              title: 'Is_Licence_Only__c',
              default: null,
              description: 'Is Licence Only',
            },
            systemModstamp: {
              $id: '#/properties/systemModstamp',
              type: ['string', 'null'],
              title: 'SystemModstamp',
              format: 'date-time',
              default: null,
              description: 'System Modstamp',
            },
            currencyIsoCode: {
              $id: '#/properties/currencyIsoCode',
              type: ['string', 'null'],
              title: 'CurrencyIsoCode',
              default: null,
              description: 'Currency ISO Code',
            },
            monthlySupportC: {
              $id: '#/properties/monthlySupportC',
              type: ['number', 'null'],
              title: 'Monthly_Support__c',
              default: null,
              description: 'Monthly Support',
            },
            contractEndDateC: {
              $id: '#/properties/contractEndDateC',
              type: ['string', 'null'],
              title: 'Contract_End_Date__c',
              format: 'date',
              default: null,
              description: 'Contract End Date',
            },
            flagshipProductC: {
              $id: '#/properties/flagshipProductC',
              type: ['boolean', 'null'],
              title: 'Flagship_Product__c',
              default: null,
              description: 'Flagship Product',
            },
            lastModifiedById: {
              $id: '#/properties/lastModifiedById',
              type: ['string', 'null'],
              title: 'LastModifiedById',
              default: null,
              description: 'Last Modified By ID',
            },
            lastModifiedDate: {
              $id: '#/properties/lastModifiedDate',
              type: ['string', 'null'],
              title: 'LastModifiedDate',
              format: 'date-time',
              default: null,
              description: 'Last Modified Date',
            },
            renewalVarianceC: {
              $id: '#/properties/renewalVarianceC',
              type: ['number', 'null'],
              title: 'Renewal_Variance__c',
              default: null,
              description: 'Renewal Variance (%)',
            },
            opportunityStageC: {
              $id: '#/properties/opportunityStageC',
              type: ['string', 'null'],
              title: 'Opportunity_Stage__c',
              default: null,
              description: 'Opportunity Stage',
            },
            contractStartDateC: {
              $id: '#/properties/contractStartDateC',
              type: ['string', 'null'],
              title: 'Contract_Start_Date__c',
              format: 'date',
              default: null,
              description: 'Contract Start Date',
            },
            isModuleOrOptionsC: {
              $id: '#/properties/isModuleOrOptionsC',
              type: ['boolean', 'null'],
              title: 'Is_Module_or_Options__c',
              default: null,
              description: 'Is Module or Options',
            },
            contractTermMonthsC: {
              $id: '#/properties/contractTermMonthsC',
              type: ['number', 'null'],
              title: 'Contract_Term_months__c',
              default: null,
              description: 'Contract Term (months)',
            },
            expectedTotalPriceC: {
              $id: '#/properties/expectedTotalPriceC',
              $ref: '#/definitions/currency',
              title: 'Expected_Total_Price__c',
              description: 'Expected Total Price',
            },
            ongoingOpportunityC: {
              $id: '#/properties/ongoingOpportunityC',
              type: ['boolean', 'null'],
              title: 'Ongoing_Opportunity__c',
              default: null,
              description: 'Ongoing Opportunity',
            },
            sofactoappAmountVatC: {
              $id: '#/properties/sofactoappAmountVatC',
              $ref: '#/definitions/currency',
              title: 'sofactoapp__Amount_VAT__c',
              description: 'Total Amount incl. VAT',
            },
            supportMonthlyPriceC: {
              $id: '#/properties/supportMonthlyPriceC',
              $ref: '#/definitions/currency',
              title: 'Support_Monthly_Price__c',
              description: 'Support Monthly Price',
            },
            monthlyPriceQuantityC: {
              $id: '#/properties/monthlyPriceQuantityC',
              $ref: '#/definitions/currency',
              title: 'Monthly_Price_Quantity__c',
              description: 'Net Monthly Price',
            },
            sofactoTempStartDateC: {
              $id: '#/properties/sofactoTempStartDateC',
              type: ['string', 'null'],
              title: 'Sofacto_Temp_Start_Date__c',
              format: 'date',
              default: null,
              description: 'Sofacto Temp Start Date',
            },
            numberOfDaysOfSupportC: {
              $id: '#/properties/numberOfDaysOfSupportC',
              type: ['number', 'null'],
              title: 'Number_of_Days_of_Support__c',
              default: null,
              description: 'Number of Days of Support',
            },
            opportunityProductMrrC: {
              $id: '#/properties/opportunityProductMrrC',
              $ref: '#/definitions/currency',
              title: 'Opportunity_Product_MRR__c',
              description: 'Opportunity Product MRR',
            },
            magentrixOneProductNameMgtrxC: {
              $id: '#/properties/magentrixOneProductNameMgtrxC',
              type: ['string', 'null'],
              title: 'MagentrixOne__ProductName_mgtrx__c',
              default: null,
              description: 'Product Name',
            },
          },
          definitions: {
            currency: {
              type: 'object',
              properties: {
                value: { type: ['number', 'null'], default: null },
                symbol: {
                  enum: [
                    'AED',
                    'AFN',
                    'ALL',
                    'AMD',
                    'ANG',
                    'AOA',
                    'ARS',
                    'AUD',
                    'AWG',
                    'AZN',
                    'BAM',
                    'BBD',
                    'BDT',
                    'BGN',
                    'BHD',
                    'BIF',
                    'BMD',
                    'BND',
                    'BOB',
                    'BRL',
                    'BSD',
                    'BTC',
                    'BTN',
                    'BWP',
                    'BYN',
                    'BYR',
                    'BZD',
                    'CAD',
                    'CDF',
                    'CHF',
                    'CLF',
                    'CLP',
                    'CNY',
                    'COP',
                    'CRC',
                    'CUC',
                    'CUP',
                    'CVE',
                    'CZK',
                    'DJF',
                    'DKK',
                    'DOP',
                    'DZD',
                    'EGP',
                    'ERN',
                    'ETB',
                    'EUR',
                    'FJD',
                    'FKP',
                    'GBP',
                    'GEL',
                    'GGP',
                    'GHS',
                    'GIP',
                    'GMD',
                    'GNF',
                    'GTQ',
                    'GYD',
                    'HKD',
                    'HNL',
                    'HRK',
                    'HTG',
                    'HUF',
                    'IDR',
                    'ILS',
                    'IMP',
                    'INR',
                    'IQD',
                    'IRR',
                    'ISK',
                    'JEP',
                    'JMD',
                    'JOD',
                    'JPY',
                    'KES',
                    'KGS',
                    'KHR',
                    'KMF',
                    'KPW',
                    'KRW',
                    'KWD',
                    'KYD',
                    'KZT',
                    'LAK',
                    'LBP',
                    'LKR',
                    'LRD',
                    'LSL',
                    'LTL',
                    'LVL',
                    'LYD',
                    'MAD',
                    'MDL',
                    'MGA',
                    'MKD',
                    'MMK',
                    'MNT',
                    'MOP',
                    'MRO',
                    'MUR',
                    'MVR',
                    'MWK',
                    'MXN',
                    'MYR',
                    'MZN',
                    'NAD',
                    'NGN',
                    'NIO',
                    'NOK',
                    'NPR',
                    'NZD',
                    'OMR',
                    'PAB',
                    'PEN',
                    'PGK',
                    'PHP',
                    'PKR',
                    'PLN',
                    'PYG',
                    'QAR',
                    'RON',
                    'RSD',
                    'RUB',
                    'RWF',
                    'SAR',
                    'SBD',
                    'SCR',
                    'SDG',
                    'SEK',
                    'SGD',
                    'SHP',
                    'SLL',
                    'SOS',
                    'SRD',
                    'STD',
                    'SVC',
                    'SYP',
                    'SZL',
                    'THB',
                    'TJS',
                    'TMT',
                    'TND',
                    'TOP',
                    'TRY',
                    'TTD',
                    'TWD',
                    'TZS',
                    'UAH',
                    'UGX',
                    'USD',
                    'UYU',
                    'UZS',
                    'VEF',
                    'VND',
                    'VUV',
                    'WST',
                    'XAF',
                    'XAG',
                    'XAU',
                    'XCD',
                    'XDR',
                    'XOF',
                    'XPF',
                    'YER',
                    'ZAR',
                    'ZMK',
                    'ZMW',
                    'ZWL',
                  ],
                  type: 'string',
                  default: 'EUR',
                },
              },
            },
          },
          description: '',
          additionalProperties: false,
        },
        createdAt: '2020-09-08T08:15:12.098Z',
        updatedAt: '2020-12-13T23:00:03.945Z',
        externalIds: ['id'],
        machineName: 'opportunityLineItem',
        preferences: { duplicates: 'overwrite' },
      },
    },
    relationships: {
      previousOpportunity: {
        name: 'previousOpportunity',
        type: 'object',
        toDefinition: { machineName: 'opportunity' },
        fromDefinition: { machineName: 'opportunity' },
        toDefinitionField: 'id',
        fromDefinitionField: 'previousOpportunityIdC',
        id: '17d5a4f6-4dd1-4518-a236-4eb400a40a65',
      },
      opportunityLineItems: {
        name: 'opportunityLineItems',
        type: 'array',
        toDefinitionMachineName: 'opportunityLineItem',
        fromDefinitionMachineName: 'opportunity',
        toDefinitionField: 'id',
        fromDefinitionField: 'id',
        id: '47c5fee4-e9ba-4259-b4e7-e47a85f0508a',
      },
    },
  },
  planAssignment: {
    id: '88a20764-0680-4093-9d47-f8786954d161',
    user: {
      id: 'c5fd0a81-9691-40ea-a1ed-29322cf8ca0a',
      email: 'pouet.plop@yolo.com',
      role: UserRole.EMPLOYEE,
      currency: 'EUR',
      language: 'en',
      lastName: 'Plop',
      settings: null,
      firstName: 'Pouet',
      pictureURL: null,
    },
    effectiveAsOf: 1_593_554_400,
    effectiveUntil: 1_609_369_200,
  },
  computedObjects: [
    {
      id: '7cb39c79482fa2f2a7d14560d2bbf250c530997c',
      type: 'VARIABLE',
      value: 200_000,
      format: 'currency',
      parentIds: [
        '1c34a805-c3f3-4963-b0ca-02276b234930',
        '78be3692a3678c5fd542764f30821d8783c310e0',
        '12fb0bfd7d6287acdf3104805a4fa01974ca6fe7',
        'c6b1fef8a83c02cfb43124fec4e602d8c7d301f2',
        'a2f994453f621cf6c6f06ee65fb165028da95483',
        '0dcff4693e797dbeae770612d39dc8bc2e953238',
      ],
      variableType: 'user',
      variableMachineName: 'target',
    },
    {
      id: '1bc7a3b97b69e40877d4883219810e01d01bb9cf',
      type: 'VARIABLE',
      value: 0.6,
      format: 'percent',
      parentIds: [
        '1c34a805-c3f3-4963-b0ca-02276b234930',
        '78be3692a3678c5fd542764f30821d8783c310e0',
        '12fb0bfd7d6287acdf3104805a4fa01974ca6fe7',
        'c6b1fef8a83c02cfb43124fec4e602d8c7d301f2',
      ],
      variableType: 'statement',
      variableMachineName: 'newAeTargetReach',
    },
    {
      id: '2271d649374603c5a93eca3d2c8e0e7affd64c64',
      type: 'VARIABLE',
      value: [
        [null, 0, 0],
        [0, 0.05, 0],
        [0.05, 0.1, 0.03],
        [0.1, 0.15, 0.07],
        [0.15, 0.2, 0.1],
        [0.2, 0.25, 0.13],
        [0.25, 0.3, 0.17],
        [0.3, 0.35, 0.2],
        [0.35, 0.4, 0.23],
        [0.4, 0.45, 0.27],
        [0.45, 0.5, 0.3],
        [0.5, 0.55, 0.33],
        [0.55, 0.6, 0.4],
        [0.6, 0.65, 0.47],
        [0.65, 0.7, 0.53],
        [0.7, 0.75, 0.6],
        [0.75, 0.8, 0.67],
        [0.8, 0.85, 0.73],
        [0.85, 0.9, 0.8],
        [0.9, 0.95, 0.87],
        [0.95, 1, 0.93],
        [1, 1.05, 1],
        [1.05, 1.1, 1.08],
        [1.1, 1.15, 1.15],
        [1.15, 1.2, 1.23],
        [1.2, 1.25, 1.3],
        [1.25, 1.3, 1.38],
        [1.3, 1.35, 1.45],
        [1.35, 1.4, 1.53],
        [1.4, 1.45, 1.6],
        [1.45, 1.5, 1.68],
        [1.5, 1.55, 1.75],
        [1.55, 1.6, 1.83],
        [1.6, 1.65, 1.9],
        [1.65, 1.7, 1.98],
        [1.7, 1.75, 2.05],
        [1.75, 1.8, 2.13],
        [1.8, 1.85, 2.2],
        [1.85, 1.9, 2.28],
        [1.9, 1.95, 2.35],
        [1.95, 2, 2.43],
        [2, null, 2.5],
      ],
      format: 'table',
      parentIds: [
        '1c34a805-c3f3-4963-b0ca-02276b234930',
        '78be3692a3678c5fd542764f30821d8783c310e0',
        '12fb0bfd7d6287acdf3104805a4fa01974ca6fe7',
        'c6b1fef8a83c02cfb43124fec4e602d8c7d301f2',
      ],
      variableType: 'statement',
      variableMachineName: 'newSalesCurve',
    },
    {
      id: '23683567270df961bc9df7bb477d121d722d64c9',
      type: 'VARIABLE',
      value: 0.47,
      format: 'percent',
      parentIds: ['1c34a805-c3f3-4963-b0ca-02276b234930', '78be3692a3678c5fd542764f30821d8783c310e0'],
      variableType: 'statement',
      variableMachineName: 'newAeAchievement',
    },
    {
      id: '6a90bbce66d521619ac3e11a1491697be99af20a',
      type: 'VARIABLE',
      value: 15_000,
      format: 'currency',
      parentIds: ['1c34a805-c3f3-4963-b0ca-02276b234930', '78be3692a3678c5fd542764f30821d8783c310e0'],
      variableType: 'user',
      variableMachineName: 'bonus',
    },
    {
      id: '47f53b68d09394857d211e67a60acee758beb10b',
      type: 'VARIABLE',
      value: 7050,
      format: 'currency',
      parentIds: ['1c34a805-c3f3-4963-b0ca-02276b234930'],
      variableType: 'statement',
      variableMachineName: 'newAeFinalBonusQuarter',
    },
    {
      type: 'RULE',
      value: 7050,
      ruleMachineName: 'newAeCommission',
    },
  ],
} as unknown as ComputedStatement;
