import { type OmitHead } from '@amalia/ext/typescript';

import { printNullOrInfinity } from '../../helpers/print-null-or-infinity/print-null-or-infinity';
import { roundNumber } from '../../helpers/round-number/round-number';
import { type FormatOptions } from '../types';

export const formatNumber = (
  locale: string,
  value: number | null | undefined,
  {
    intlOptions,
    formatOptions,
  }: {
    intlOptions?: Omit<Intl.NumberFormatOptions, 'style'>;
    formatOptions?: FormatOptions;
  } = {},
) =>
  printNullOrInfinity(value, formatOptions?.printNullOrInfinity, () =>
    new Intl.NumberFormat(locale, intlOptions).format(roundNumber(value)),
  );

export type FormatNumber = (...args: OmitHead<Parameters<typeof formatNumber>>) => ReturnType<typeof formatNumber>;
