import { forwardRef, type HTMLAttributes, type LegacyRef, memo } from 'react';

import { FormatsEnum, isUserModelRefValue, type PropertyRef } from '@amalia/data-capture/fields/types';
import { Typography } from '@amalia/design-system/components';
import { FormattedAmount, FormattedValue } from '@amalia/kernel/intl/components';
import { type CurrencyValue, isCurrencyValue } from '@amalia/kernel/monetary/types';
import { type KeysOfUserWithStringValues } from '@amalia/tenants/users/types';

import { BooleanPrettyFormat, toBoolean } from './boolean';
import { accentuatedLabel, defaultLabel, dimmedLabel, label } from './FieldValuePrettyFormat.styles';
import { LabelVariant, TypographyVariantMap } from './types';
import { UserContainer } from './user';

export { LabelVariant };
export { isFieldValuePrettyFormatCompatible } from './FieldValuePrettyFormat.compatibility';

export type ValueWithFormat<Format extends FormatsEnum, Value> = {
  format?: Format;
  value?: Value | null;
};

export const getPrettyFormatProps = (
  format?: FormatsEnum,
  value?: unknown,
  valueRef?: PropertyRef,
): ValuesWithFormats & { valueRef?: PropertyRef } => {
  if (isUserModelRefValue(value, valueRef)) {
    return { format: FormatsEnum.text, value, valueRef };
  }
  if (format === FormatsEnum.boolean) {
    return { format, value: value as boolean };
  }

  if (format === FormatsEnum.currency) {
    return { format, value: value as CurrencyValue };
  }
  return { format: FormatsEnum.text, value: value as string };
};

export type ValuesWithFormats =
  | ValueWithFormat<(typeof FormatsEnum)['date-time'], string>
  | ValueWithFormat<FormatsEnum.boolean, boolean | string>
  | ValueWithFormat<FormatsEnum.currency, CurrencyValue | number | string>
  | ValueWithFormat<FormatsEnum.date, string>
  | ValueWithFormat<FormatsEnum.number, number>
  | ValueWithFormat<FormatsEnum.percent, number>
  | ValueWithFormat<FormatsEnum.table, string>
  | ValueWithFormat<FormatsEnum.text, string>;

export type FieldValuePrettyFormatProps = HTMLAttributes<HTMLDivElement> &
  ValuesWithFormats & {
    readonly variant?: LabelVariant;
    readonly valueRef?: PropertyRef;
    readonly userField?: KeysOfUserWithStringValues;
  };

const FieldValuePrettyFormatForwardRef = forwardRef(function FieldValuePrettyFormat(
  { format, value, valueRef, userField, variant = LabelVariant.DEFAULT, ...restProps }: FieldValuePrettyFormatProps,
  ref?: LegacyRef<HTMLDivElement>,
) {
  const cssStyles = [
    variant === LabelVariant.DEFAULT && defaultLabel,
    variant === LabelVariant.ACCENTUATED && accentuatedLabel,
    variant === LabelVariant.DIMMED && dimmedLabel,
  ];
  return (
    <div
      ref={ref}
      {...restProps}
      css={label}
    >
      {(() => {
        // PropertyRef has higher priority than format to display the correct component
        if (isUserModelRefValue(value, valueRef)) {
          return (
            <UserContainer
              property={userField || 'externalId'}
              value={value}
              variant={variant}
            />
          );
        }

        switch (format) {
          case FormatsEnum.boolean:
            return (
              <BooleanPrettyFormat
                value={toBoolean(value)}
                variant={variant}
              />
            );

          case FormatsEnum.currency:
            return (
              <Typography
                css={cssStyles}
                variant={TypographyVariantMap[variant]}
              >
                {isCurrencyValue(value) ? (
                  <FormattedAmount
                    currencySymbol={value.symbol}
                    value={value.value}
                  />
                ) : (
                  // This is a fallback for the case where we don't have the currency symbol.
                  // I'm not sure this would happen.
                  <FormattedValue
                    format={FormatsEnum.text}
                    value={value}
                  />
                )}
              </Typography>
            );

          // This case should not happen because the compatible formats are already handled above.
          default:
            return (
              <Typography
                css={cssStyles}
                variant={TypographyVariantMap[variant]}
              >
                <FormattedValue
                  format={FormatsEnum.text}
                  value={value}
                />
              </Typography>
            );
        }
      })()}
    </div>
  );
});

export const FieldValuePrettyFormat = memo(FieldValuePrettyFormatForwardRef);
