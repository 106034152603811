import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type OmitHead } from '@amalia/ext/typescript';

import { printNullOrInfinity } from '../../helpers/print-null-or-infinity/print-null-or-infinity';
import { type FormatOptions } from '../types';

export const formatAmount = (
  locale: string,
  value: number | null | undefined,
  currency: CurrencySymbolsEnum,
  {
    currencyRate = 1,
    intlOptions,
    formatOptions,
  }: {
    currencyRate?: number;
    intlOptions?: Omit<Intl.NumberFormatOptions, 'currency' | 'style'>;
    formatOptions?: FormatOptions;
  } = {},
) =>
  printNullOrInfinity(value, formatOptions?.printNullOrInfinity, () => {
    const totalAmount = (value ?? 0) * currencyRate;

    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      trailingZeroDisplay: 'stripIfInteger',
      ...intlOptions,
      style: 'currency',
      currency,
    }).format(
      // If the amount is contained between -0.004999... and 0.0049999..., it
      // will be rounded then displayed as -0 and we want to avoid that.
      Math.abs(totalAmount) < 0.005 ? 0 : totalAmount,
    );
  });

export type FormatAmount = (...args: OmitHead<Parameters<typeof formatAmount>>) => ReturnType<typeof formatAmount>;
