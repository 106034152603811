import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useSnackbars } from '@amalia/design-system/components';
import { toError } from '@amalia/ext/typescript';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import { customReportsMutationKeys, customReportsQueryKeys } from './custom-reports.keys';

export const useCustomReportsList = () =>
  useQuery({
    queryKey: customReportsQueryKeys.list(),
    queryFn: () => CustomReportsApiClient.getCustomReports(),
    placeholderData: [],
  });

export const useCustomReport = (id: string) =>
  useQuery({
    queryKey: customReportsQueryKeys.ofCustomReport.details(id),
    queryFn: () => CustomReportsApiClient.getCustomReportById(id),
  });

export const useCreateCustomReports = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportsMutationKeys.create(),
    mutationFn: CustomReportsApiClient.createCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: customReportsQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomReport = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportsMutationKeys.patch(),
    mutationFn: CustomReportsApiClient.patchCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: customReportsQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDuplicateCustomReport = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportsMutationKeys.duplicate(),
    mutationFn: CustomReportsApiClient.duplicateCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: customReportsQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useDeleteCustomReport = () => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: customReportsMutationKeys.delete(),
    mutationFn: CustomReportsApiClient.deleteCustomReport,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: customReportsQueryKeys.all() });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
