import { css } from '@emotion/react';
import { IconCircle } from '@tabler/icons-react';

import { type MergeAll } from '@amalia/ext/typescript';

import { IconButton } from '../../general/icon-button/IconButton';
import { Group } from '../../layout/group/Group';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';

interface ColorOption<TColorValue> {
  value: TColorValue;
  label: string;
  color: string;
}

export type ColorPickerProps<TColorValue> = MergeAll<
  [
    UseFormFieldPropsOptions,
    {
      /** Value. */
      value?: TColorValue;
      /** On change handler. */
      onChange: (value: TColorValue) => void;
      /** Color options. */
      colorOptions: ColorOption<TColorValue>[];
    },
  ]
>;

export const ColorPicker = function ColorPicker<TColorValue extends string = string>(
  props: ColorPickerProps<TColorValue>,
) {
  const {
    formFieldProps,
    otherProps: { value, onChange, colorOptions },
  } = useFormFieldProps(props);

  return (
    <FormField {...formFieldProps}>
      <Group
        gap={4}
        css={css`
          flex-wrap: wrap;
        `}
      >
        {colorOptions.map(({ color, value: optionValue, label }) => (
          <IconButton
            key={optionValue}
            color={color}
            isActive={optionValue === value}
            label={label}
            icon={
              <IconCircle
                color={color}
                fill={color}
              />
            }
            onClick={() => onChange(optionValue)}
          />
        ))}
      </Group>
    </FormField>
  );
};
