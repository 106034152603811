import { css } from '@emotion/react';
import { omit } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Dropdown, type DropdownProps } from '../dropdown/Dropdown';
import { DropdownGroup } from '../dropdown/dropdown-group/DropdownGroup';

import { DropdownListItem } from './dropdown-list-item/DropdownListItem';
import {
  isDropdownListItemGroup,
  type DropdownListItem as DropdownListItemShape,
  type DropdownListItemGroup,
} from './DropdownList.types';
import { useFilterItems } from './hooks/useFilterItems';
import { PaginatedDropdownListItems } from './paginated-dropdown-list-items/PaginatedDropdownListItems';

export type DropdownListProps = Omit<DropdownProps, 'content' | 'searchInput'> & {
  readonly items: (DropdownListItemGroup | DropdownListItemShape)[];
  readonly hideSearchInput?: boolean;
  readonly searchInputPlaceholder?: string;
};

const DropdownListBase = memo(function DropdownList({
  items,
  children,
  hideSearchInput,
  searchInputPlaceholder,
  ...props
}: DropdownListProps) {
  const [searchText, setSearchText] = useState('');

  const filteredItemsAndGroups = useFilterItems(items, searchText);

  const flatItems = useMemo(
    () => items.flatMap((itemOrGroup) => (isDropdownListItemGroup(itemOrGroup) ? itemOrGroup.items : itemOrGroup)),
    [items],
  );

  const hasGroups = filteredItemsAndGroups.some(isDropdownListItemGroup);

  // Automatically hide search input if there are less than 6 flat options (in single mode) or 5 flat options (in multiple mode) and no groups.
  // This behavior can be overridden by explicitely setting hideSearchInput.
  const shouldHideSearchInput = hideSearchInput ?? flatItems.length <= 6;

  return (
    <Dropdown
      {...props}
      content={
        filteredItemsAndGroups.length ? (
          <ul
            data-testid={props.id ? `${props.id}-list` : undefined}
            css={css`
              list-style-type: none;
            `}
          >
            {!hasGroups ? (
              <PaginatedDropdownListItems
                id={props.id}
                items={filteredItemsAndGroups as DropdownListItemShape[]}
              />
            ) : (
              filteredItemsAndGroups.map((itemOrGroup) =>
                isDropdownListItemGroup(itemOrGroup) ? (
                  <DropdownGroup
                    key={itemOrGroup.items[0].key}
                    {...omit(itemOrGroup, 'items')}
                  >
                    <PaginatedDropdownListItems
                      id={props.id}
                      items={itemOrGroup.items}
                    />
                  </DropdownGroup>
                ) : (
                  <DropdownListItem
                    key={itemOrGroup.key}
                    id={props.id}
                    item={itemOrGroup}
                  />
                ),
              )
            )}
          </ul>
        ) : (
          <Dropdown.NoOptions>
            <FormattedMessage defaultMessage="No plans are using this token" />
          </Dropdown.NoOptions>
        )
      }
      searchInput={
        !shouldHideSearchInput ? (
          <Dropdown.SearchInput
            placeholder={searchInputPlaceholder}
            value={searchText}
            onChange={setSearchText}
          />
        ) : undefined
      }
    >
      {children}
    </Dropdown>
  );
});

export const DropdownList = Object.assign(DropdownListBase, {
  Button: Dropdown.Button,
  IconButton: Dropdown.IconButton,
});
