import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';

export type CellNoValueProps = ComponentPropsWithoutRef<typeof TableDataCellContent>;

export const CellNoValue = memo(function CellNoValue({ children, ...props }: CellNoValueProps) {
  return (
    <TableDataCellContent
      {...props}
      css={(theme) => css`
        color: ${theme.ds.colors.gray['500']};
        text-wrap: nowrap;
      `}
    >
      {children ?? <FormattedMessage defaultMessage="No value" />}
    </TableDataCellContent>
  );
});
