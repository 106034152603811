import { css } from '@emotion/react';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { memo, type ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { buildPropertiesOptions } from '@amalia/data-capture/fields/components';
import { type CustomObjectDefinition, getProperties } from '@amalia/data-capture/record-models/types';
import {
  Button,
  FieldSize,
  FormFieldLabel,
  Group,
  IconButton,
  InlineInputs,
  Select,
  type SelectOption,
  Stack,
} from '@amalia/design-system/components';
import { type FilterOrderBy } from '@amalia/payout-definition/plans/types';

import { useSortOptionsByFormat } from './useSortOptionsByFormat';

interface OrderByFormComponentProps {
  readonly customObjectDefinition?: CustomObjectDefinition;
  readonly value: FilterOrderBy[] | null;
  readonly onChange: (value: FilterOrderBy[]) => void;
  readonly helpLabel?: ReactNode;
}

export const OrderByFormComponent = memo(function OrderByFormComponent({
  customObjectDefinition,
  value,
  onChange,
  helpLabel,
}: OrderByFormComponentProps) {
  const propertyOptions = useMemo(
    () => buildPropertiesOptions(getProperties(customObjectDefinition), customObjectDefinition?.externalIds[0] ?? null),
    [customObjectDefinition],
  );

  const onChangeFieldMachineName = useCallback(
    (index: number, fieldMachineName: string) => {
      onChange(value!.map((v, i) => (i === index ? { ...v, fieldMachineName } : v)));
    },
    [value, onChange],
  );

  const onChangeDirection = useCallback(
    (index: number, direction: 'ASC' | 'DESC') => {
      onChange(value!.map((v, i) => (i === index ? { ...v, direction } : v)));
    },
    [value, onChange],
  );

  const handleAddElement = useCallback(() => {
    const propertyToAdd = customObjectDefinition && getProperties(customObjectDefinition).at(0)?.machineName;
    if (propertyToAdd) {
      onChange((value ?? []).concat({ fieldMachineName: propertyToAdd, direction: 'ASC' as const }));
    }
  }, [value, onChange, customObjectDefinition]);

  const handleDelete = useCallback(
    (index: number) => {
      onChange((value ?? []).filter((_, i) => index !== i));
    },
    [value, onChange],
  );

  const sortOptionsByFormat = useSortOptionsByFormat();

  return (
    <Stack gap={value?.length ? 12 : 6}>
      <FormFieldLabel
        size={FieldSize.SMALL}
        tooltip={helpLabel}
      >
        <FormattedMessage defaultMessage="Sort matched records" />
      </FormFieldLabel>
      <Stack gap={12}>
        {(value ?? []).map(({ fieldMachineName, direction }, index) => {
          const format = fieldMachineName ? customObjectDefinition?.properties[fieldMachineName]?.format : null;
          const sortOptions = format ? sortOptionsByFormat[format] : [];

          return (
            <Group
              // eslint-disable-next-line react/no-array-index-key -- no id to order on here.
              key={index}
              align="center"
              gap={12}
            >
              <InlineInputs
                css={css`
                  flex: 1;
                  max-width: 600px;
                `}
              >
                <Select<SelectOption<string>, false, false, false>
                  isClearable={false}
                  options={propertyOptions}
                  size={Select.Size.SMALL}
                  value={fieldMachineName}
                  onChange={(newValue) => onChangeFieldMachineName(index, newValue)}
                />
                <Select<SelectOption<'ASC' | 'DESC'>, false, false, false>
                  isClearable={false}
                  options={sortOptions}
                  size={Select.Size.SMALL}
                  value={direction}
                  onChange={(newValue) => onChangeDirection(index, newValue)}
                />
              </InlineInputs>
              <IconButton
                icon={<IconTrash />}
                label={<FormattedMessage defaultMessage="Delete sort" />}
                size={IconButton.Size.SMALL}
                variant={IconButton.Variant.DANGER}
                onClick={() => handleDelete(index)}
              />
            </Group>
          );
        })}
      </Stack>
      <div>
        <Button
          icon={<IconPlus />}
          size={Button.Size.SMALL}
          variant={Button.Variant.PRIMARY_LIGHT}
          onClick={handleAddElement}
        >
          <FormattedMessage defaultMessage="Add sort" />
        </Button>
      </div>
    </Stack>
  );
});
