import { Fragment, memo, type ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { type RequiredDeep } from 'type-fest';
import { type LocaleObject, setLocale, ValidationError } from 'yup';

export type YupLocaleUpdaterProps = {
  readonly children?: ReactNode;
};

export const YupLocaleUpdater = memo(function YupLocaleUpdater({ children = undefined }: YupLocaleUpdaterProps) {
  const { formatMessage, formatList } = useIntl();

  // Define everything as methods because locale bundle might not be loaded on mount.
  useEffect(() => {
    const mixed: Required<LocaleObject['mixed']> = {
      default: () => formatMessage({ defaultMessage: 'This field is invalid.' }),
      required: () => formatMessage({ defaultMessage: 'This field is required.' }),
      defined: () => formatMessage({ defaultMessage: 'This field is required.' }),
      notNull: () => formatMessage({ defaultMessage: 'This field is required.' }),
      oneOf: ({ values }: { values: string[] | string }) =>
        formatMessage(
          { defaultMessage: 'This field must be one of the following values: {values}.' },
          { values: formatList(([] as string[]).concat(values), { type: 'disjunction' }) },
        ),
      notOneOf: ({ values }: { values: string[] | string }) =>
        formatMessage(
          { defaultMessage: 'This field must not be one of the following values: {values}.' },
          { values: formatList(([] as string[]).concat(values), { type: 'disjunction' }) },
        ),
      notType: ({ type }: { type: string }) =>
        formatMessage({ defaultMessage: 'This field must be of type {type}.' }, { type }),
    };

    const string: Required<LocaleObject['string']> = {
      length: ({ length }) =>
        formatMessage(
          { defaultMessage: 'This field must be exactly {length, plural, one {# character} other {# characters}}.' },
          { length },
        ),
      min: ({ min }) =>
        formatMessage(
          { defaultMessage: 'This field must be at least {min, plural, one {# character} other {# characters}}.' },
          { min },
        ),
      max: ({ max }) =>
        formatMessage(
          { defaultMessage: 'This field must be at most {max, plural, one {# character} other {# characters}}.' },
          { max },
        ),
      matches: ({ regex }) =>
        formatMessage(
          { defaultMessage: 'This field must match the following: "{regex}".' },
          { regex: regex.toString() },
        ),
      email: () => formatMessage({ defaultMessage: 'This field must be a valid email.' }),
      url: () => formatMessage({ defaultMessage: 'This field must be a valid URL.' }),
      uuid: () => formatMessage({ defaultMessage: 'This field must be a valid UUID.' }),
      trim: () => formatMessage({ defaultMessage: 'This field must be a trimmed string.' }),
      lowercase: () => formatMessage({ defaultMessage: 'This field must be a lowercase string.' }),
      uppercase: () => formatMessage({ defaultMessage: 'This field must be an uppercase string.' }),
      datetime: () => formatMessage({ defaultMessage: 'This field must be a valid ISO date-time.' }),
      datetime_offset: () =>
        formatMessage({ defaultMessage: 'This field must be a valid ISO date-time with UTC "Z" timezone.' }),
      datetime_precision: ({ precision }) =>
        formatMessage(
          {
            defaultMessage:
              'This field must be a valid ISO date-time with a sub-second precision of exactly {precision, number} digits.',
          },
          { precision },
        ),
    };

    const number: Required<LocaleObject['number']> = {
      min: ({ min }) =>
        formatMessage({ defaultMessage: 'This field must be greater than or equal to {min, number}.' }, { min }),
      max: ({ max }) =>
        formatMessage({ defaultMessage: 'This field must be less than or equal to {max, number}.' }, { max }),
      lessThan: ({ less }) =>
        formatMessage({ defaultMessage: 'This field must be less than {less, number}.' }, { less }),
      moreThan: ({ more }) =>
        formatMessage({ defaultMessage: 'This field must be greater than {more, number}.' }, { more }),
      positive: () => formatMessage({ defaultMessage: 'This field must be a positive number.' }),
      negative: () => formatMessage({ defaultMessage: 'This field must be a negative number.' }),
      integer: () => formatMessage({ defaultMessage: 'This field must be an integer.' }),
    };

    const date: Required<LocaleObject['date']> = {
      min: ({ min }) => formatMessage({ defaultMessage: 'This field must be later than {min}.' }, { min }),
      max: ({ max }) => formatMessage({ defaultMessage: 'This field must be at earlier than {max}.' }, { max }),
    };

    const boolean: Required<LocaleObject['boolean']> = {
      isValue: ({ value }: { value: string }) =>
        formatMessage({ defaultMessage: 'This field must be {value}.' }, { value }),
    };

    const object: Required<LocaleObject['object']> = {
      noUnknown: ({ unknown }: { unknown: string[] }) =>
        formatMessage({ defaultMessage: 'This field has unspecified keys: {unknown}.' }, { unknown }),
      exact: ({ properties }) =>
        formatMessage({ defaultMessage: 'This field has unknown keys: {properties}.' }, { properties }),
    };

    const array: Required<LocaleObject['array']> = {
      min: ({ min }) =>
        formatMessage(
          { defaultMessage: 'This field must have at least {min, plural, one {# item} other {# items}}.' },
          { min },
        ),
      max: ({ max }) =>
        formatMessage(
          { defaultMessage: 'This field must have less than or equal to {max, plural, one {# item} other {# items}}.' },
          { max },
        ),
      length: ({ length }) =>
        formatMessage(
          { defaultMessage: 'This field must have {length, plural, one {# item} other {# items}}.' },
          { length },
        ),
    };

    const tuple: Required<LocaleObject['tuple']> = {
      notType: (params: { value: unknown; spec: { types: unknown[] } }) => {
        const {
          value,
          spec: { types },
        } = params;

        if (Array.isArray(value) && value.length !== types.length) {
          return formatMessage(
            { defaultMessage: 'This field must have {length, plural, one {# item} other {# items}}.' },
            { length: types.length },
          );
        }

        return ValidationError.formatError(mixed.notType, params) as string;
      },
    };

    setLocale({
      mixed,
      string,
      number,
      date,
      boolean,
      object,
      array,
      tuple,
    } satisfies RequiredDeep<LocaleObject>);
  }, [formatMessage, formatList]);

  return <Fragment>{children}</Fragment>;
});
