import { useQuery } from '@tanstack/react-query';

import { type CustomReport } from '@amalia/reporting/custom-reports/shared';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import { customReportsQueryKeys } from './custom-reports.keys';

export const useCustomReportSourcesManifests = (customReport: CustomReport | null, canEdit: boolean) => {
  const { data, ...rest } = useQuery({
    queryKey: customReportsQueryKeys.ofCustomReport.manifests(
      customReport?.id ?? 'not-loaded',
      canEdit ? (customReport?.configuration ?? null) : null,
    ),
    queryFn: () => CustomReportsApiClient.getManifests(customReport!.id, canEdit ? customReport!.configuration : null),
    enabled: !!customReport,
    placeholderData: (prev) => prev,
  });

  return {
    data: data ?? {},
    ...rest,
  };
};
