import { dayjs } from '@amalia/ext/dayjs';
import { type OmitHead } from '@amalia/ext/typescript';

import { printNullOrInfinity } from '../../helpers/print-null-or-infinity/print-null-or-infinity';
import { type FormatOptions } from '../types';

export const formatDate = (
  locale: string,
  value: number | string | null,
  {
    formatOptions,
  }: {
    formatOptions?: FormatOptions;
  } = {},
) =>
  printNullOrInfinity(value, formatOptions?.printNullOrInfinity, () =>
    // Note: I adapted the original code but maybe this should use dayjs.utc?
    dayjs(value, 'X', true).isValid() ? dayjs(value, 'X').locale(locale).format('YYYY-MM-DD') : `${value ?? ''}`,
  );

export type FormatDate = (...args: OmitHead<Parameters<typeof formatDate>>) => ReturnType<typeof formatDate>;
