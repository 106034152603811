import { uniqueId } from 'lodash';
import { useState } from 'react';

/**
 * Generate a unique id if no id is given as parameter.
 * The random id is generated on mount. If prefix changes, the id is not updated.
 *
 * @param id - If provided, will be returned without generating a new value.
 * @param prefix - If no id provided, will generate a unique id with this prefix.
 */
export const useUniqueId = ({
  id,
  prefix,
}: {
  id?: string;
  prefix?: string;
} = {}): string => {
  // Generate a random id on mount. Never update.
  // eslint-disable-next-line react/hook-use-state
  const [randomId] = useState(() => uniqueId(prefix));

  // Return the id if provided, fallback on generated id.
  return id ?? randomId;
};
