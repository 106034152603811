import { type FormikFieldProps, useFormikFieldAdapter } from '@amalia/ext/formik';

import { type ColorPickerProps, ColorPicker } from '../ColorPicker';

export type FormikColorPickerProps<TColorValue> = FormikFieldProps<ColorPickerProps<TColorValue>>;

const FormikColorPickerBase = function FormikColorPickerBase<TColorValue extends string = string>({
  validate, // Omit validate and any props not passed to ColorPicker.
  ...props
}: FormikColorPickerProps<TColorValue>) {
  const formikFieldProps = useFormikFieldAdapter<ColorPickerProps<TColorValue>['value']>({
    ...props,
    validate,
  });

  return (
    <ColorPicker
      {...props}
      {...formikFieldProps}
    />
  );
};

export const FormikColorPicker = FormikColorPickerBase;
