/* eslint-disable formatjs/no-literal-string-in-jsx */
import styled from '@emotion/styled';
import { IconArrowLeft, IconCornerDownLeft } from '@tabler/icons-react';
import { useCommandState } from 'cmdk';
import { Fragment, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Divider, IconButton, KbdContainer, Typography, UnstyledButton } from '@amalia/design-system/components';
import { ColorCategory } from '@amalia/design-system/meta';

import { useShortcutCommandContext } from '../../ShortcutCommands.context';
import { type PagesEnum } from '../shortcut-commands.types';

import { useActions } from './use-actions';

type ActionsFooterProps = {
  readonly page: PagesEnum | undefined;
};

const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 8px;
  border-top: ${({ theme }) => `1px solid ${theme.ds.colors[ColorCategory.GRAY][100]}`};
`;

const ActionButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  gap: 6px;

  & > span {
    color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][700]};
  }
  & kbd {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${({ theme }) => theme.ds.colors[ColorCategory.GRAY][700]};
    border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
    border: ${({ theme }) => `1px solid ${theme.ds.colors[ColorCategory.GRAY][100]}`};
    padding: 2px 6px;
    font-family: Roboto;
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.055px;
  }
`;

export const ActionsFooter = memo(function ActionsFooter({ page }: ActionsFooterProps) {
  const { formatMessage } = useIntl();
  const valueSelected = useCommandState((state) => state.value) as string;

  const { primaryAction, secondaryAction, shiftSecondaryAction, shiftPrimaryAction } = useActions(page);

  const { resetDialog } = useShortcutCommandContext();

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      // Secondary Actions (ctrl + enter and shift + ctrl + enter)
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (e.shiftKey) {
          shiftSecondaryAction?.onSelect(valueSelected);
        } else {
          secondaryAction?.onSelect(valueSelected);
        }
        return;
      }
      // Shift primary Action (shift + enter)
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        e.stopImmediatePropagation();
        shiftPrimaryAction?.onSelect(valueSelected);
      }
    };

    document.addEventListener('keydown', keyDownListener, true);

    return () => {
      document.removeEventListener('keydown', keyDownListener, true);
    };
  }, [secondaryAction, shiftPrimaryAction, shiftSecondaryAction, valueSelected]);

  if (!page) {
    return null;
  }

  return (
    <FooterContainer>
      <IconButton
        icon={<IconArrowLeft />}
        label={formatMessage({ defaultMessage: 'Go back to commands' })}
        onClick={resetDialog}
      />
      <ActionsContainer>
        {!!primaryAction && (
          <ActionButton onClick={() => primaryAction.onSelect(valueSelected)}>
            <KbdContainer variant="light">
              ENTER
              <IconCornerDownLeft
                height="12px"
                width="12px"
              />
            </KbdContainer>
            <Typography variant={Typography.Variant.BODY_XSMALL_MEDIUM}>{primaryAction.title}</Typography>
          </ActionButton>
        )}
        {!!secondaryAction && (
          <Fragment>
            <Divider.Vertical />
            <ActionButton onClick={() => secondaryAction.onSelect(valueSelected)}>
              <KbdContainer variant="light">
                CTRL +
                <IconCornerDownLeft
                  height="12px"
                  width="12px"
                />
              </KbdContainer>
              <Typography variant={Typography.Variant.BODY_XSMALL_MEDIUM}>{secondaryAction.title}</Typography>
            </ActionButton>
          </Fragment>
        )}
      </ActionsContainer>
    </FooterContainer>
  );
});
