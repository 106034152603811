import { round } from 'lodash';

const MIN_AMOUNT = 0.01;

/** 0.01 => 2 decimal places. */
export const getDecimalPlacesFromRoundingConstant = (roundingConstant: number): number =>
  Math.max(Math.ceil(Math.log10(1 / roundingConstant)), 0);

// TODO replace with decimalPlaces instead, it's only used in one place as a static value.
export const roundNumber = (numberToRound?: number | null, roundingConstant = 0.01): number =>
  !numberToRound || (numberToRound < MIN_AMOUNT && numberToRound > -1 * MIN_AMOUNT)
    ? 0
    : round(numberToRound, getDecimalPlacesFromRoundingConstant(roundingConstant));
