import { useQuery } from '@tanstack/react-query';

import { type CustomReport, type CustomReportFacetRequest } from '@amalia/reporting/custom-reports/shared';

import { CustomReportsApiClient } from '../api-client/custom-reports.api-client';

import { customReportsQueryKeys } from './custom-reports.keys';

export const useCustomReportFacet = (
  customReportId: CustomReport['id'] | null,
  customReportFacetRequest: CustomReportFacetRequest | null,
) =>
  useQuery({
    queryKey: customReportsQueryKeys.ofCustomReport.facet(customReportId!, customReportFacetRequest!),
    queryFn: () => CustomReportsApiClient.getFacet(customReportId!, customReportFacetRequest!),
    enabled: !!customReportId && !!customReportFacetRequest,
  });
