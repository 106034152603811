import { Fragment, memo } from 'react';

import { type FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { type FormatOptions } from '@amalia/kernel/intl/formatters';
import { type ComputeEnginePrimitiveTypes } from '@amalia/payout-calculation/types';

import { useFormatValue } from '../../hooks/use-format-value';

export type FormattedValueProps = {
  readonly value: ComputeEnginePrimitiveTypes | undefined;
  readonly format: FormatsEnum;
  readonly currencySymbol?: CurrencySymbolsEnum;
  readonly currencyRate?: number;
  readonly intlOptions?: Intl.NumberFormatOptions;
  readonly formatOptions?: FormatOptions;
};

export const FormattedValue = memo(function FormattedValue({
  value,
  format,
  currencySymbol,
  currencyRate,
  intlOptions,
  formatOptions,
}: FormattedValueProps) {
  const formatValue = useFormatValue();

  return (
    <Fragment>
      {formatValue(value, format, {
        currencySymbol,
        currencyRate,
        intlOptions,
        formatOptions,
      })}
    </Fragment>
  );
});
