import { css } from '@emotion/react';
import { isFunction } from 'lodash';
import { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amalia/ext/typescript';

import { Typography, type TypographyProps } from '../../../general/typography/Typography';

export type DropdownNoOptionsProps = MergeAll<
  [
    Omit<TypographyProps, 'as' | 'children' | 'variant'>,
    {
      searchInputValue?: string;
      children?: ReactNode | ((props: { searchInputValue: string }) => ReactNode);
    },
  ]
>;

export const DropdownNoOptions = memo(function DropdownNoOptions({
  searchInputValue = '',
  children = undefined,
  ...props
}: DropdownNoOptionsProps) {
  return (
    <Typography
      {...props}
      as="div"
      variant={Typography.Variant.BODY_SMALL_BOLD}
      css={(theme) => css`
        color: ${theme.ds.colors.gray[500]};
        word-break: break-word;

        padding: 0 8px;

        &:only-child {
          padding: 6px 8px;
        }
      `}
    >
      {(isFunction(children) ? children({ searchInputValue }) : children) ??
        (searchInputValue ? (
          <FormattedMessage
            defaultMessage={`No options matching "{value}"`}
            values={{ value: searchInputValue }}
          />
        ) : (
          <FormattedMessage defaultMessage="No options to select" />
        ))}
    </Typography>
  );
});
