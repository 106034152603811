import { css } from '@emotion/react';
import { IconKey } from '@tabler/icons-react';

import { DesignerTokenIcon } from '@amalia/amalia-lang/tokens/components';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { type FormatsEnum, type Property } from '@amalia/data-capture/fields/types';
import { Group } from '@amalia/design-system/components';

export const buildPropertiesOptions = (
  properties: Property[] | undefined,
  externalId: string | null,
  format?: FormatsEnum,
) =>
  (properties ?? [])
    .filter(format ? (property) => property.format === format : () => true)
    .toSorted((a, b) => {
      if (a.machineName === externalId) {
        return -1;
      }
      if (b.machineName === externalId) {
        return 1;
      }
      return a.machineName.localeCompare(b.machineName);
    })
    .map((property) => ({
      label:
        externalId === property.machineName ? (
          <Group
            align="center"
            justify="space-between"
          >
            {property.name}
            <IconKey
              size={16}
              css={css`
                flex-shrink: 0;
              `}
            />
          </Group>
        ) : (
          property.name
        ),
      filterLabel: `${property.machineName} ${property.name}`,
      value: property.machineName,
      valueLabel: property.name,
      icon: (
        <DesignerTokenIcon
          tokenFormat={property.format}
          tokenType={TokenType.PROPERTY}
        />
      ),
    }));
