import styled from '@emotion/styled';
import { IconChevronDown } from '@tabler/icons-react';

export const CollapseIconChevron = styled(IconChevronDown, {
  shouldForwardProp: (prop) => !['isOpen', 'startAngle', 'endAngle'].includes(prop),
})<{ isOpen: boolean; startAngle?: number; endAngle?: number }>`
  flex: none;
  transition: ${({ theme }) => theme.ds.transitions.default()};
  transform: ${({ isOpen, startAngle = -0, endAngle = -180 }) =>
    isOpen ? `rotate(${endAngle}deg)` : `rotate(${startAngle}deg)`};
`;
