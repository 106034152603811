import {
  type CustomReportFacetRequest,
  type CustomReport,
  type CustomReportConfiguration,
  type CustomReportDataSourceConfiguration,
  type CustomReportRecordsRequest,
} from '@amalia/reporting/custom-reports/shared';

export const customReportsQueryKeys = {
  all: () => ['custom-reports'] as const,
  list: () => [...customReportsQueryKeys.all(), 'list'] as const,
  ofCustomReport: {
    all: (customReportId: CustomReport['id']) =>
      [...customReportsQueryKeys.all(), 'of-custom-report', customReportId] as const,
    details: (customReportId: CustomReport['id']) =>
      [...customReportsQueryKeys.ofCustomReport.all(customReportId), 'details'] as const,
    facet: (customReportId: CustomReport['id'], customReportFacetRequest: CustomReportFacetRequest) =>
      [...customReportsQueryKeys.ofCustomReport.all(customReportId), 'facet', customReportFacetRequest] as const,
    manifests: (customReportId: CustomReport['id'], customReportConfiguration: CustomReport['configuration'] | null) =>
      [
        ...customReportsQueryKeys.ofCustomReport.all(customReportId),
        'manifests',
        // Only updating the manifests when we change the join config.
        customReportConfiguration?.joins,
      ] as const,
    content: (
      customReportId: CustomReport['id'],
      configuration: CustomReportConfiguration | undefined,
      dataSourceConfiguration: CustomReportDataSourceConfiguration | undefined,
      pagination: CustomReportRecordsRequest['pagination'],
    ) =>
      [
        ...customReportsQueryKeys.ofCustomReport.all(customReportId),
        'content',
        configuration,
        dataSourceConfiguration,
        pagination,
      ] as const,
  } as const,
} as const;

export const customReportsMutationKeys = {
  all: () => ['custom-reports'] as const,
  create: () => [...customReportsMutationKeys.all(), 'create'] as const,
  patch: () => [...customReportsMutationKeys.all(), 'patch'] as const,
  duplicate: () => [...customReportsMutationKeys.all(), 'duplicate'] as const,
  delete: () => [...customReportsMutationKeys.all(), 'delete'] as const,
} as const;

export const customReportFoldersQueryKeys = {
  all: () => ['custom-reports-folders'] as const,
  list: () => [...customReportFoldersQueryKeys.all(), 'list'] as const,
} as const;

export const customReportFoldersMutationKeys = {
  all: () => ['custom-reports-folders'] as const,
  create: () => [...customReportFoldersMutationKeys.all(), 'create'] as const,
  patch: () => [...customReportFoldersMutationKeys.all(), 'patch'] as const,
  delete: () => [...customReportFoldersMutationKeys.all(), 'delete'] as const,
} as const;
