import { Fragment, memo } from 'react';

import { type FormatOptions } from '@amalia/kernel/intl/formatters';

import { useFormatPercent } from '../../hooks/use-format-percent';

export type FormattedPercentProps = {
  readonly value: number | null;
  readonly intlOptions?: Intl.NumberFormatOptions;
  readonly formatOptions?: FormatOptions;
};

export const FormattedPercent = memo(function FormattedPercent({
  value,
  intlOptions,
  formatOptions,
}: FormattedPercentProps) {
  const formatPercent = useFormatPercent();
  return <Fragment>{formatPercent(value, { intlOptions, formatOptions })}</Fragment>;
});
